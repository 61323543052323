import { Navigate } from "react-router-dom";
import { checkAuthority } from "api/checkAuthority";
import { toast } from 'react-toastify';

const PrivateRoute = ({ children, authority }) => {
  if (!checkAuthority(authority)) {
    toast("접근 권한이 없습니다. 로그인 후 다시 시도하십시오.", {type: 'error'});
    return <Navigate to={"/login"} />;
  }
  return children;
};

export default PrivateRoute;