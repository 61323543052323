import axios from "axios";

async function request(object) {
  try {
    const token = localStorage.getItem('token');

    if (token) {
      object.headers = {
        ...object.headers,
        Authorization: `Bearer ${token}`
      };
    }

    const response = await axios.create({
      baseURL: process.env.REACT_APP_DOXMEET_API_BASEURL ?? "https://api.club.ku.ster.ws",
      headers: {
        "Content-type": "application/json"
      }
    })(object);

    if (!response.data.success) {
      throw response.data;
    }

    if (response.headers.token) {
      localStorage.setItem('token', response.headers.token);
    }

    return response.data;
  } catch(e) {
    if (e.error.code === 'ERR104') {
      localStorage.removeItem('token');
      window.location = "/login";
    }

    return e;
  }
}

export default request;