import { useState, useEffect } from "react";
import request from "api/http-request";
import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';
import PageHead from 'Components/PageHead';

export default function ClubSetting() {
  const [userEmail, setUserEmail] = useState("");
  const [divisions, setDivisions] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);

  const setClubAction = async (club) => {
    const method = selectedClubs.some((c) => c.id === club.id)
      ? "DELETE"
      : "POST";
    const result = await request({
      method,
      url: `/user/${userEmail}/club/${club.id}`,
    });

    if (result && result.success) {
      const decoded_token = jwt_decode(localStorage.getItem("token"));
      setSelectedClubs(decoded_token.club);
      return toast(
        `${club.name} 동아리를 ${method === "DELETE" ? "삭제" : "추가"}했습니다.`,
        { type: "success" }
      );
    } else {
      return toast(
        <div>
          <h3 className="font-bold">오류가 발생했습니다.</h3>
          <p>{`${result.error.code} - ${result.error.message}`}</p>
        </div>,
        { type: "error" }
      );
    }
  };

  useEffect(() => {
    (async () => {
      const resultClub = await request({
        method: "GET",
        url: "/club",
      });

      if (resultClub && resultClub.success) {
        setClubs(resultClub.club);
      } else {
        toast(
          <div>
            <h3 className="font-bold">오류가 발생했습니다.</h3>
            <p>{`${resultClub.error.code} - ${resultClub.error.message}`}</p>
          </div>,
          { type: "error" }
        );
      }

      const resultDivision = await request({
        method: "GET",
        url: "/division",
      });

      if (resultDivision && resultDivision.success) {
        return setDivisions(resultDivision.division);
      } else {
        return toast(
          <div>
            <h3 className="font-bold">오류가 발생했습니다.</h3>
            <p>{`${resultDivision.error.code} - ${resultDivision.error.message}`}</p>
          </div>,
          { type: "error" }
        );
      }
    })();

    const decoded_token = jwt_decode(localStorage.getItem("token"));
    setUserEmail(decoded_token.email);
    setSelectedClubs(decoded_token.club);
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 mt-8 lg:px-8">
      <div className="w-full mb-8">
        <PageHead title="내 동아리 설정" description="" to="/user/club" />
        <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
          <div className="flex flex-wrap space-y-8">
            {divisions.map((division) => (
              <div
                className="flex flex-wrap w-full space-y-3"
                key={division.id}
              >
                <div className="flex-auto w-full">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {division.name}
                  </h3>
                  {/* <p className="mt-1 text-sm text-gray-500">분과설명</p> */}
                </div>
                <div className="flex-auto flex flex-wrap justify-start items-center">
                  {clubs
                    .filter((club) => club.division.id === division.id)
                    .map((club) => (
                      <ul className="flex w-auto mr-2 mb-1" key={club.id}>
                        <li>
                          <input
                            type="checkbox"
                            id={club.id}
                            checked={selectedClubs.some(
                              (c) => c.id === club.id
                            )}
                            onChange={() => setClubAction(club)}
                            className="hidden peer"
                          />
                          <label
                            htmlFor={club.id}
                            className="inline-flex justify-between items-center px-2 py-1 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-rose-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50"
                          >
                            <div className="block">
                              <div className="w-full text-lg font-semibold">
                                {club.name}
                              </div>
                            </div>
                          </label>
                        </li>
                      </ul>
                      // <div
                      //   className="relative flex items-start mr-6 w-1/3 lg:w-auto"
                      //   key={club.id}
                      // >
                      //   <div className="flex items-center h-5">
                      //     <input
                      //       name="club"
                      //       type="checkbox"
                      //       checked={selectedClubs.some(
                      //         (c) => c.id === club.id
                      //       )}
                      //       onChange={() => setClubAction(club)}
                      //       className="focus:ring-rose-500 h-4 w-4 text-rose-600 border-gray-300 rounded"
                      //     />
                      //   </div>
                      //   <div className="ml-2 text-sm">
                      //     <label
                      //       htmlFor="club"
                      //       className="font-base text-gray-700 whitespace-nowrap"
                      //     >
                      //       {club.name}
                      //     </label>
                      //   </div>
                      // </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}