import { useEffect, useState, useRef, Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react/solid';
import { Link, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const navigation = [
  { name: '소개', path: '/about' },
  { name: '상시공지', path: '/notice-regular' },
  { name: '기간공지', path: '/notice-term' },
  { name: '자유게시판', path: '/board/1' },
  { name: '시설대관', path: '/rent' },
  { name: '시설보고', path: '/board/3' },
  { name: '서류제출', path: '/document' },
  { name: '문의', path: '/board/2' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header({token, setToken, setHeaderHeight}) {
  const [user, setUser] = useState({
    email: '',
    name: '',
    club: [],
    authority: [],
    exp: 0,
  });

  const headerRef = useRef();

  const logoutAction = () => {
    localStorage.removeItem('token');
    setToken('');
    setUser({});
  }

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight);
    
    if (token) {
      const decoded_token = jwt_decode(token);
      setUser(decoded_token);
    } else {
      setUser({
        email: "",
        name: "",
        club: [],
        authority: [],
        exp: 0,
      });
    }
  }, [token, setHeaderHeight]);

  const location = useLocation();

  return (
    <>
      <Disclosure as="nav" className="bg-white shadow" ref={headerRef}>
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 lg:px-8">
              <div className="relative flex justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/" className="block lg:hidden">
                      <img src="/logo.png" alt="logo" className="h-8 w-auto" />
                    </Link>
                    <Link to="/" className="hidden lg:block">
                      <img src="/logo.png" alt="logo" className="h-8 w-auto" />
                    </Link>
                  </div>
                  <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.path}
                        className={classNames(
                          location.pathname.includes(item.path)
                            ? 'border-red-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                        )}
                        aria-current={
                          location.pathname.includes(item.path)
                            ? 'page'
                            : undefined
                        }
                      >
                        {item.name}
                      </Link>
                    ))}
                    {user.name && user.authority.join('').includes('관리') && (
                      <Link
                        to="/management"
                        className={classNames(
                          '/management' ===
                            `/${location.pathname.split('/')[1]}`
                            ? 'border-red-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                        )}
                        aria-current={
                          '/management' ===
                          `/${location.pathname.split('/')[1]}`
                            ? 'page'
                            : undefined
                        }
                      >
                        관리
                      </Link>
                    )}
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0 z-50">
                  {/* Profile dropdown */}
                  {user.name ? (
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="bg-white flex text-sm">
                          <span className="hidden md:block">{user.name}님</span>
                          <UserCircleIcon className="block md:hidden text-gray-500 w-6 h-6" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item className="block px-4 py-2 text-sm text-gray-700 md:hidden">
                            <span>{user.name}님</span>
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/user/club"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                내 동아리 설정
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/user/change-phone"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                전화번호 등록
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/user/change-password"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                비밀번호 변경
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                onClick={logoutAction}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                )}
                              >
                                로그아웃
                              </span>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <Link
                      to="/login"
                      className="bg-white p-1 text-gray-500 hover:text-gray-900 font-medium text-sm"
                    >
                      <span>로그인</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="pt-2 pb-4 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={Link}
                    to={item.path}
                    className={classNames(
                      location.pathname.includes(item.path)
                        ? 'bg-rose-50 border-rose-500 text-rose-700'
                        : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                      'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                    )}
                    aria-current={
                      location.pathname.includes(item.path)
                        ? 'page'
                        : undefined
                    }
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}