import React, { useState } from 'react';
import request from 'api/http-request';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { MailIcon, KeyIcon, CheckCircleIcon } from '@heroicons/react/solid';

export default function ResetPassword() {
  const navigate = useNavigate();

  const [ userEmail, setUserEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setpasswordConfirm ] = useState('');
  const [ code, setCode ] = useState('');
  const [ isEmailSent, setIsEmailSent ] = useState(false);
  const [ verified, setVerified ] = useState(false);

  // 비밀번호 찾기 인증 메일 발송
  const sendVerifyAction = async () => {
    if (!userEmail) return toast('아이디를 입력해주세요.', {type: 'error'});

    const result = await request({
      method: 'POST',
      url: `/user/${userEmail}/find-password/validation`
    });

    if (result && result.success) {
      setIsEmailSent(true);
      toast(<div><h3 className="font-bold">인증 메일을 발송했습니다.</h3><p>메일함에서 인증코드를 확인해주세요.</p></div>, {type: 'success'});
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  // 인증 번호 확인
  const verifyAction = async () => {
    if (!userEmail) return toast('아이디를 입력해주세요.', {type: 'error'});
    if (!code) return toast('인증코드를 입력해주세요.', {type: 'error'});
    
    const result = await request({
      method: 'GET',
      url: `/cert/${userEmail}/FindPassword/${code}`,
    })

    if (result && result.success) {
      toast('비밀번호 변경이 완료되었습니다.', {type: 'success'});
      return setVerified(true);
    } else {
      return toast(<div><h3 className="font-bold">인증에 실패했습니다.</h3><p>인증코드를 다시 확인하신 후 입력해주세요.</p></div>, {type: 'error'});
    }
  }
  
  // 비밀번호 변경 요청
  const passwordChangeAction = async (event) => {
    event.preventDefault();

    if (!userEmail) return toast('아이디를 입력해주세요.', {type: 'error'});
    if (!verified) return toast('먼저 인증해주세요.', {type: 'error'});
    if (!password) return toast('비밀번호를 입력해주세요.', {type: 'error'});
    if (password !== passwordConfirm) return toast('비밀번호가 일치하지 않습니다.', {type: 'error'});

    const result = await request({
      method: 'PUT',
      url: `/user/${userEmail}/find-password`,
      data: {
        code,
        password,
      },
    });

    if (result && result.success) {
      navigate('/login');
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  return (
    <div
      className="flex items-center justify-center px-6 lg:px-0"
      style={{ minHeight: "calc(100vh - 64px)" }}
    >
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-bold text-gray-900">
            비밀번호 찾기
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={passwordChangeAction}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                아이디
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10 w-0">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MailIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="id"
                    id="id"
                    className={`${isEmailSent && 'bg-gray-200'} focus:ring-rose-500 focus:border-rose-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300`}
                    placeholder="아이디"
                    disabled={isEmailSent}
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                  />
                  <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      @korea.ac.kr
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  disabled={isEmailSent}
                  className={`${
                    isEmailSent ? "bg-gray-200" : "hover:bg-gray-100"
                  } -ml-px relative inline-flex items-center space-x-2 px-2 lg:px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50`}
                  onClick={sendVerifyAction}
                >
                  <span>인증코드 발송</span>
                </button>
              </div>
            </div>
          </div>

          {isEmailSent && (
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                인증코드
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <CheckCircleIcon
                      className={[
                        verified ? "text-emerald-400" : "text-gray-400",
                        "h-4",
                        "w-4",
                      ].join(" ")}
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="code"
                    id="code"
                    maxLength={6}
                    className={`${verified && "bg-gray-200"} focus:ring-rose-500 focus:border-rose-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300`}
                    placeholder="인증코드"
                    disabled={verified}
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value.toUpperCase());
                    }}
                  />
                </div>
                <button
                  type="button"
                  className={`${
                    verified ? "bg-gray-200" : "hover:bg-gray-100"
                  } -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50`}
                  disabled={verified}
                  onClick={verifyAction}
                >
                  <span>{verified ? "인증완료" : "인증"}</span>
                </button>
              </div>
            </div>
          )}

          {verified && (
            <>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    비밀번호
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <KeyIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                        placeholder="비밀번호"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    비밀번호 확인
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <KeyIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="password"
                        name="passwordConfirm"
                        id="passwordConfirm"
                        className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                        placeholder="비밀번호 확인"
                        value={passwordConfirm}
                        onChange={(e) => {
                          setpasswordConfirm(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="pt-2">
            <button
              type="submit"
              className={`${
                verified ? "bg-rose-800 hover:bg-rose-900" : "bg-gray-300"
              } group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white`}
              disabled={!verified}
            >
              비밀번호 변경
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};