import { useEffect, useRef } from 'react';
import request from 'api/http-request';
import { toast } from 'react-toastify';
// TOAST UI Editor for React
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'Styles/editor.css';
// Toast UI Editor 한국어 파일
import '@toast-ui/editor/dist/i18n/ko-kr';
// TOAST UI Editor : Color Syntax 플러그인
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
// TOAST UI Editor : Table Merged Cell 플러그인
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';
import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import md5 from 'md5';

export default function ToastEditor({ contents, setContents, commentBtnRef }) {
  // Editor Ref 생성
  const editorRef = useRef();

  const toolbarItems = commentBtnRef
    ? [
        ["bold", "italic"],
        ["image", "link"],
      ]
    : [
        ["heading", "bold", "italic", "strike"],
        ["hr", "quote"],
        ["ul", "ol", "task"],
        ["indent", "outdent"],
        ["table", "image", "link"],
      ];

  const onLoad = (editor) => {
    const html = editor.getHTML();
    if (html !== contents) editor.setHTML(contents);
  }

  // 키 입력 시 Editor의 html값을 State에 설정
  const onChange = () => {
    if (!editorRef.current) return;
    const html = editorRef.current.getInstance().getHTML();
    setContents(html);
  }

  useEffect(() => {
    if (!editorRef.current) return;
    const html = editorRef.current.getInstance().getHTML();
    if (html !== contents) editorRef.current.getInstance().setHTML(contents);
  }, [contents])

  useEffect(() => {
    if (commentBtnRef) {
      const proseMirror = editorRef.current
        .getInstance()
        .wwEditor.el.querySelector("div.ProseMirror.toastui-editor-contents");
      if (proseMirror.clientWidth < proseMirror.offsetWidth) {
        const scrollerWidth = proseMirror.offsetWidth - proseMirror.clientWidth;
        const scrollerWidthString = `${scrollerWidth}px`;
        commentBtnRef.current.style.right = `calc(1.25rem + ${scrollerWidthString})`;
      } else {
        commentBtnRef.current.style.right = "1.25rem";
      }
    }
  }, [commentBtnRef]);

  return (
    <div className="text-lg-wrapper text-link-wrapper">
      <Editor
        initialEditType="wysiwyg"
        initialValue={contents.comment ? contents.comment : contents}
        usageStatistics={false}
        useCommandShortcut={false}
        hideModeSwitch={true}
        language="ko"
        height={commentBtnRef ? '300px' : '600px'}
        toolbarItems={toolbarItems}
        plugins={[colorSyntax, tableMergedCell]}
        ref={editorRef}
        onChange={onChange}
        onLoad={onLoad}
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            const form = new FormData();
            form.append("image", blob, md5(blob.name));
            form.append("name", encodeURIComponent(blob.name));
            
            const result = await request({
              method: "POST",
              url: "/image/upload",
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
              },
              data: form,
            });

            if (result && result.success) {
              callback(`https://api.club.ku.ster.ws${result.link}`, blob.name);
            } else {
              toast(
                <div>
                  <h3 className="font-bold">오류가 발생했습니다.</h3>
                  <p>{`${result.error.code} - ${result.error.message}`}</p>
                </div>,
                { type: "error" }
              );
            }
          },
        }}
      />
    </div>
  );
}