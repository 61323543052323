import { useState, useEffect, useCallback } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import { SearchIcon } from "@heroicons/react/solid";
import PageHead from 'Components/PageHead';
import { toast } from 'react-toastify';
import request from 'api/http-request';
import moment from 'moment';
import 'moment/locale/ko';
import Pagination from "Components/Pagenation";

moment.locale('ko');

const boardName = '문의';
const boardBaseUrl = '/board/2';
const boardDescription = '동아리연합회 1:1 문의 게시판입니다';

export default function RequestBoard() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') ?? 1;

  const [contents, setContents] = useState({
    total_number_of_posts: undefined,
    number_of_posts_per_page: undefined,
    this_page_number: undefined,
    is_private: undefined,
    is_anonymous: undefined,
    posts: [],
  });

  const [keyword, setKeyword] = useState('');

  function getDateString(date) {
    const postDate = moment(date);
    if (moment().diff(postDate) > (1000 * 60 * 60 * 24)) {
      return postDate.format('yyyy-MM-DD');
    } else {
      return postDate.fromNow();
    }
  }

  const getPostList = useCallback((keyword = '') => {
    (async () => {
      const url = `${boardBaseUrl}?page=${page}${
        keyword && `&search=${keyword}`
      }`;
      const result = await request({
        method: "GET",
        url,
      });

      if (result && result.success) {
        setContents(result.board);
      } else {
        toast(
          <div>
            <h3 className="font-bold">오류가 발생했습니다.</h3>
            <p>{`${result.error.code} - ${result.error.message}`}</p>
          </div>,
          { type: "error" }
        );
      }
    })();
  }, [page]);

  useEffect(() => {
    getPostList();
  }, [getPostList]);

  useEffect(() => {
    const timer = setTimeout(() => { getPostList(keyword) }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [getPostList, keyword]);

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div className="w-full">
      <PageHead title={boardName} description={boardDescription} to={boardBaseUrl} />
        <div className="bg-white shadow overflow-hidden rounded-md border border-stone-200">
          <ul className="divide-y divide-gray-200">
            <li key="head" className="hidden lg:block">
              <div className="px-4 py-4">
                <div className="flex justify-between lg:px-4">
                  <p className="flex-initial w-9/12 font-bold text-gray-800">
                    제목
                  </p>
                  <p className="flex-initial w-2/12 font-bold text-gray-800 lg:text-center">
                    작성자
                  </p>
                  <p className="flex-auto text-center font-bold text-gray-800">
                    날짜
                  </p>
                </div>
              </div>
            </li>
            {contents.posts.length ?
              (contents.posts.map((post) => (
                <li key={post.id}>
                  <Link
                    to={`${boardBaseUrl}/${post.id}`}
                    className="block hover:bg-gray-50"
                    state={{ page }}
                  >
                    <div className="px-4 py-4">
                      <div className="flex flex-wrap justify-between lg:px-4">
                        <p className="flex-initial w-full font-base text-gray-800 truncate lg:w-9/12">
                          {post.title}
                          {post.number_of_comments > 0 && <small className="ml-1">{`[${post.number_of_comments}]`}</small>}
                        </p>
                        <p className="flex-initial w-3/5 truncate text-xs font-base text-gray-500 lg:text-gray-800 lg:text-center lg:text-base lg:w-2/12">
                          {post.name}
                        </p>
                        <p className="flex-auto text-right text-xs font-base text-gray-500 lg:text-gray-700 lg:text-center lg:text-base lg:w-auto">
                          {getDateString(post.written_at)}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
              ))) : <li className="flex justify-center items-center text-gray-500 py-6">작성된 게시물이 없습니다.</li>}
          </ul>
          {(contents.posts.length !== 0) && (
            <Pagination
              total={contents.total_number_of_posts}
              limit={contents.number_of_posts_per_page}
              page={page}
            />
          )}
        </div>

        <div className="flex justify-between items-center space-x-4 mt-4 mb-4">
          <div>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  className="focus:ring-red-500 focus:border-red-500 block w-full rounded-md pl-10 border-gray-300"
                />
              </div>
            </div>
          </div>
          <div>
            <Link to={`${boardBaseUrl}/write`}>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent font-medium whitespace-nowrap rounded shadow-sm text-white bg-rose-600 hover:bg-rose-700"
              >
                글쓰기
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}