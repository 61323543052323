import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import request from 'api/http-request';
import jwtDecode from 'jwt-decode';

export default function Login({ setToken }) {
  const navigate = useNavigate();

  const [ id, setId ] = useState('');
  const [ password, setPassword ] = useState('');
  
  const loginAction = async (event) => {
    event.preventDefault();

    if (!id) return toast('아이디를 입력해주세요.', {type: 'error'});
    if (!password) return toast('비밀번호를 입력해주세요.', {type: 'error'});

    const result = await request({
      method: 'POST',
      url: `/user/${id}/login`,
      data: {
        password,
      },
    });

    if (result && result.success) {
      setToken(result.token);
      localStorage.setItem('token', result.token);

      const decodedToken = jwtDecode(result.token);
      if (decodedToken.authority.length <= 1 && !decodedToken.club.length) return navigate('/user/club');
    } else {
      toast(result.error.message, {type: 'error'});
    }
  }

  return (
    <div className="flex items-center justify-center px-6 lg:px-0" style={{minHeight: 'calc(100vh - 64px)'}}>
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
            로그인
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={loginAction}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                아이디
              </label>
              <input
                id="id"
                name="text"
                type="text"
                autoComplete="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-rose-500 focus:border-rose-500 focus:z-10 sm:text-sm"
                placeholder="아이디"
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                비밀번호
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-rose-500 focus:border-rose-500 focus:z-10 sm:text-sm"
                placeholder="비밀번호"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex items-right justify-end">
            <div className="text-sm">
              <Link
                to="/reset-password"
                className="font-medium text-rose-600 pr-4"
              >
                비밀번호 찾기
              </Link>
              <Link to="/register" className="font-medium text-rose-600">회원가입</Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-rose-800 hover:bg-rose-900"
              onClick={loginAction}
            >
              로그인
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};