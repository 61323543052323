import { useSearchParams } from 'react-router-dom';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';

export default function Pagination({ total, limit}) {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const page = Number(searchParams.get('page') ?? 1);

  const numPages = Math.ceil(total / limit);
  const pageSetStart = (Math.ceil(page / 5) - 1) * 5 + 1;
  const pageSetEnd = Math.ceil(page / 5) * 5;
  const pageSet = [];
  for (let i = pageSetStart; i <= pageSetEnd; i++) {
    if (i > numPages) {
      break;
    }
    pageSet.push(i);
  }

  return (
    <nav className="border-t border-gray-200 px-6 pb-4 flex items-center justify-between lg:px-6">
      <div className={`${pageSetStart <= 1 && 'invisible'} -mt-px w-0 flex-1 flex`}>
        <div
          onClick={() => {
            setSearchParams({page: pageSetStart - 1});
          }}
          className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:border-rose-300 cursor-pointer"
        >
          <ArrowNarrowLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span className="hidden lg:inline">이전</span>
        </div>
      </div>
      <div className="lg:-mt-px lg:flex">
        {pageSet
          .map((v) => (
            <div key={v} onClick={() => {setSearchParams({page: v})}} className={`${page === v ? 'border-rose-500 text-rose-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer`}>
              {v}
            </div>
          ))}
      </div>
      <div className={`${pageSetEnd >= numPages && 'invisible'} -mt-px w-0 flex-1 flex justify-end`}>
        <div
          onClick={() => {
            setSearchParams({page: pageSetEnd + 1});
          }}
          className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:border-rose-300 cursor-pointer"
        >
          <span className="hidden lg:inline">다음</span>
          <ArrowNarrowRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </nav>
  );
}