import '@toast-ui/editor/dist/toastui-editor-viewer.css';

import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import request from 'api/http-request';
import moment from 'moment';
import { checkAuthority } from 'api/checkAuthority';
import Modal from 'Components/Modal';
import { toast } from 'react-toastify';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Tooltip } from 'flowbite-react';
import { Viewer } from '@toast-ui/react-editor';
import PageHead from 'Components/PageHead';

const boardName = '기간공지';
const boardBaseUrl = '/notice-term';
const boardDescription = '';

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export default function NoticeRegularPost() {
  const params = useParams();
  const navigate = useNavigate();

  const viewerRef = useRef();

  const location = useLocation();
  const page = location.state?.page ?? 1;

  const [ post, setPost ] = useState({
    id: undefined,
    title: "",
    contents: "",
    written_at: "",
    modified_at: "",
    exposure_from: "",
    exposure_to: "",
    files: []
  });
  const [ postDeleteModal, setPostDeleteModal ] = useState(false);

  const postId = params.id;

  // 날짜 표시 함수
  const formatDate = (written_at, modified_at) => {
    const written = moment(written_at);
    const modified = moment(modified_at);

    if (written.diff(modified) === 0) return written.format('yyyy-MM-DD H:mm:ss');
    return <>{written.format('yyyy-MM-DD H:mm:ss')}<div className="inline-block"><Tooltip content={modified.format('yyyy-MM-DD H:mm:ss')} trigger="hover"><span className="text-xs text-gray-500">&nbsp;(수정됨)</span></Tooltip></div></>;
  }

  // 게시물 삭제 함수
  const deletePostAction = async () => {
    const result = await request({
      method: 'DELETE',
      url: `${boardBaseUrl}/${postId}`,
    });
    if (result && result.success) {
      setPostDeleteModal(!postDeleteModal);
      navigate(`${boardBaseUrl}?page=${page}`);
    } else {
      setPostDeleteModal(!postDeleteModal);
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  useEffect(() => {
    (async () => {
      const url = `${boardBaseUrl}/${postId}`;
      const result = await request({
        method: "GET",
        url,
      });

      if (result && result.success) {
        viewerRef.current.getInstance().setMarkdown(result.post.contents);
        setPost(result.post);
      } else {
        toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
      }
    })();
  }, [postId]);

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div className="w-full">
        <PageHead
          title={boardName}
          description={boardDescription}
          to={boardBaseUrl}
        />
        <div className="bg-white shadow overflow-hidden rounded-md mb-4">
          <div className="px-4 py-5 border-b border-gray-200 lg:px-8">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap">
              <div className="ml-4 mt-2">
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                  {post.title}
                </h3>
              </div>
              <div className="ml-auto mt-2">
                <h3 className="text-base leading-6 font-medium text-gray-500">
                  {post.written_at &&
                    formatDate(post.written_at, post.modified_at)}
                </h3>
              </div>
            </div>
          </div>
          <div className="py-4 px-4 border-b border-gray-200 text-lg-wrapper lg:py-6 lg:px-8">
            <Viewer ref={viewerRef} />
          </div>
        </div>

        {post.files?.length !== 0 && (
          <div className="bg-white shadow overflow-hidden rounded-md">
            <div className="px-4 py-3 border-b border-gray-200 lg:px-8">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap">
                <div className="ml-4 mt-2">
                  <h3 className="leading-6 font-bold text-gray-900">
                    첨부파일
                  </h3>
                </div>
              </div>
            </div>
            <div className="divide-y">
              {post.files.map((file) => (
                <a
                  className="flex px-4 py-5 items-center justify-between hover:bg-gray-200 lg:px-8 lg:justify-start"
                  href={`https://api.club.ku.ster.ws${file.link}`}
                  target="_blank"
                  rel="noreferrer"
                  key={file.id}
                >
                  <p className="w-3/4 text-sm font-medium text-gray-900 truncate lg:w-auto">
                    {file.name}
                  </p>
                  <p className="text-right text-sm font-base text-gray-600">
                    &nbsp;({formatBytes(file.size)})
                  </p>
                </a>
              ))}
            </div>
          </div>
        )}

        <div className="flex justify-between my-4">
          <div>
            <button
              type="button"
              className="inline-flex items-center mr-2 px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-gray-500 hover:bg-gray-600"
              onClick={() => navigate(`${boardBaseUrl}?page=${page}`)}
            >
              목록으로
            </button>
          </div>
          {checkAuthority("관리자") && (
            <div>
              <button
                type="button"
                className="inline-flex items-center mr-2 px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-rose-600 hover:bg-red-700"
                onClick={() =>
                  navigate(`${boardBaseUrl}/edit/${postId}`, {
                    state: { page },
                  })
                }
              >
                수정
              </button>
              <button
                type="button"
                onClick={() => setPostDeleteModal(!postDeleteModal)}
                className="inline-flex items-center px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-rose-600 hover:bg-rose-700"
              >
                삭제
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="정말 삭제하시겠습니까?"
        description="정말 삭제하시겠습니까? 삭제한 게시물은 복구할 수 없습니다."
        open={postDeleteModal}
        setOpen={setPostDeleteModal}
        action={deletePostAction}
      >
        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </Modal>
    </div>
  );
}