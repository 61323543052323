import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { SearchIcon, DocumentTextIcon } from "@heroicons/react/solid";
import PageHead from "Components/PageHead";
import { toast } from "react-toastify";
import request from "api/http-request";
import moment from "moment";
import "moment/locale/ko";
import Pagination from "Components/Pagenation";
import { checkAuthority } from "api/checkAuthority";
import { useCallback } from "react";

moment.locale("ko");

const boardName = "상시공지";
const boardBaseUrl = "/notice-regular";
const boardDescription = "";

export default function NoticeRegular() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?? 1;

  const [contents, setContents] = useState({
    total_number_of_posts: undefined,
    number_of_posts_per_page: undefined,
    this_page_number: undefined,
    is_private: undefined,
    is_anonymous: undefined,
    posts: [],
  });

  const [keyword, setKeyword] = useState("");

  function getDateString(date) {
    const postDate = moment(date);
    if (moment().diff(postDate) > 1000 * 60 * 60 * 24) {
      return postDate.format("yyyy-MM-DD");
    } else {
      return postDate.fromNow();
    }
  }

  const getPostList = useCallback((keyword = '') => {
    (async () => {
      const url = `${boardBaseUrl}?page=${page}${
        keyword && `&search=${keyword}`
      }`;
      const result = await request({
        method: "GET",
        url,
      });

      if (result && result.success) {
        setContents(result.notice_regular);
      } else {
        toast(
          <div>
            <h3 className="font-bold">오류가 발생했습니다.</h3>
            <p>{`${result.error.code} - ${result.error.message}`}</p>
          </div>,
          { type: "error" }
        );
      }
    })();
  }, [page]);

  useEffect(() => {
    getPostList();
  }, [getPostList]);

  useEffect(() => {
    const timer = setTimeout(() => { getPostList(keyword) }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [getPostList, keyword]);

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div className="w-full">
        <PageHead
          title={boardName}
          description={boardDescription}
          to={boardBaseUrl}
        />
        <div className="bg-white shadow overflow-hidden rounded-md border border-stone-200">
          <ul className="divide-y divide-gray-200">
            <li key="head" className="hidden lg:block">
              <div className="px-4 py-4">
                <div className="flex justify-between lg:px-4">
                  <p className="flex-initial w-11/12 font-bold text-gray-800">
                    제목
                  </p>
                  <p className="flex-auto text-center font-bold text-gray-800">
                    날짜
                  </p>
                </div>
              </div>
            </li>
            {contents.posts.length ? (
              contents.posts.map((post) => (
                <li key={post.id}>
                  <Link
                    to={`${boardBaseUrl}/${post.id}`}
                    className="block hover:bg-gray-50"
                    state={{ page }}
                  >
                    <div className="px-4 py-4">
                      <div className="flex flex-wrap justify-between lg:px-4">
                        <div className="flex-initial w-full flex items-center space-x-0.5 font-base text-gray-800 lg:w-11/12">
                          <p className="w-11/12 truncate lg:w-auto">
                            <span>{post.title}</span>
                            {post.number_of_files !== 0 && (
                              <DocumentTextIcon className="relative -top-0.5 h-4 w-4 ml-1 inline-block text-gray-600" />
                            )}
                          </p>
                        </div>
                        <p className="flex-auto text-right text-xs font-base text-gray-500 lg:text-gray-700 lg:text-center lg:text-base lg:w-auto">
                          {getDateString(post.written_at)}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            ) : (
              <li className="flex justify-center items-center text-gray-500 py-6">
                작성된 게시물이 없습니다.
              </li>
            )}
          </ul>
          {contents.posts.length !== 0 && (
            <Pagination
              total={contents.total_number_of_posts}
              limit={contents.number_of_posts_per_page}
              page={page}
            />
          )}
        </div>

        <div className="flex justify-between items-center space-x-4 mt-4 mb-4">
          <div>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  className="focus:ring-red-500 focus:border-red-500 block w-full rounded-md pl-10 border-gray-300"
                />
              </div>
            </div>
          </div>
          {checkAuthority("관리자") && (
            <div>
              <Link to={`${boardBaseUrl}/write`}>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent font-medium whitespace-nowrap rounded shadow-sm text-white bg-rose-600 hover:bg-rose-700"
                >
                  글쓰기
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
