import { useState, useEffect, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import request from 'api/http-request';
import PageHead from 'Components/PageHead';
import { toast } from 'react-toastify';
import ToastEditor from "Components/Editor";
import Dropzone from 'Components/Dropzone';
import jwtDecode from 'jwt-decode';

const boardName = '서류제출';
const boardBaseUrl = '/document';
const boardDescription = '재등록서약서 등 주요 서류를 제출할 수 있습니다';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DocumentWrite() {
  const navigate = useNavigate();

  const [ title, setTitle ] = useState('');
  const [ contents, setContents ] = useState('');
  const [ clubs, setClubs ] = useState([]);
  const [ clubId, setClubId ] = useState(null);
  const [ clubName, setClubName ] = useState('');
  const [ files, setFiles ] = useState([]);

  const writeAction = async () => {
    if (!title) return toast('제목을 입력해주세요.', {type: 'error'});
    if (!contents) return toast('내용을 입력해주세요.', {type: 'error'});
    if (!clubName) return toast('동아리 이름을 입력해주세요.', {type: 'error'});

    const result = await request({
      method: 'POST',
      url: boardBaseUrl,
      data: {
        clubId,
        clubName,
        title,
        contents,
        files
      },
    });

    if (result && result.success) {
      navigate(`${boardBaseUrl}/${result.documentId}`, {state: {page: 1}});
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  useEffect(() => {
    if (clubId === 0) return setClubName('');
    if (clubId !== null) setClubName(clubs.find(club => club.id === clubId).name);
  }, [clubs, clubId])

  useEffect(() => {
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    decodedToken.club.push({
      id: 0,
      name: "기타",
      division: {
        id: 0,
        name: "기타",
      },
    })
    setClubs(decodedToken.club);
    setClubId(decodedToken.club[0].id);
  }, [])

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div className="w-full">
        <PageHead
          title={boardName}
          description={boardDescription}
          to={boardBaseUrl}
        />
        <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
          <div className="w-full lg:w-10/12">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              제목
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                className="shadow-sm focus:ring-rose-500 focus:border-rose-500 block w-full border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="w-full lg:w-2/12">
            <Listbox value={clubId} onChange={setClubId}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium text-gray-700">
                    동아리
                  </Listbox.Label>
                  <div className="mt-1 relative">
                    <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm lg:h-10">
                      <span className="block truncate">{clubs.find(club => club.id === clubId)?.name}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {clubs.map((club) => (
                          <Listbox.Option
                            key={club.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-rose-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={club.id}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {club.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-rose-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          {clubId === 0 && (
            <div className="w-full lg:w-auto">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                동아리 이름
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="clubName"
                  value={clubName}
                  onChange={(e) => {
                    setClubName(e.target.value);
                  }}
                  className="shadow-sm focus:ring-rose-500 focus:border-rose-500 block w-full border-gray-300 rounded-md"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-6">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          내용
        </label>
        <ToastEditor contents={contents} setContents={setContents} />
      </div>

      <Dropzone files={files} setFiles={setFiles} className="mt-6" />

      <div className="flex justify-end my-4">
        <button
          type="button"
          onClick={writeAction}
          className="inline-flex items-center px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600"
        >
          작성
        </button>
      </div>
    </div>
  );
}