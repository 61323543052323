import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import request from 'api/http-request';
import PageHead from 'Components/PageHead';
import { toast } from 'react-toastify';
import ToastEditor from "Components/Editor";

const boardName = '시설보고';
const boardBaseUrl = '/board/3';
const boardDescription = '시설 사용 후 뒷정리 사진을 제출하는 게시판입니다';

export default function RequestBoardWrite() {
  const navigate = useNavigate();

  const [ title, setTitle ] = useState('');
  const [ contents, setContents ] = useState('');

  const writeAction = async () => {
    if (!title) return toast('제목을 입력해주세요.', {type: 'error'});
    if (!contents) return toast('내용을 입력해주세요.', {type: 'error'});

    const result = await request({
      method: 'POST',
      url: boardBaseUrl,
      data: {
        title,
        contents
      },
    });

    if (result && result.success) {
      navigate(`${boardBaseUrl}/${result.postId}`, {state: {page: 1}});
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div>
      <PageHead title={boardName} description={boardDescription} to={boardBaseUrl} />
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            제목
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="title"
              id="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className="shadow-sm focus:ring-rose-500 focus:border-rose-500 block w-full border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
      
      <div className="mt-6">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          내용
        </label>
        <ToastEditor contents={contents} setContents={setContents} />
      </div>

      <div className="flex justify-end my-4">
        <button
          type="button"
          onClick={writeAction}
          className="inline-flex items-center px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600"
        >
          작성
        </button>
      </div>
    </div>
  );
}