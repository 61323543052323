export default function About() {
  return (
    <div className="relative py-16 overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-ku font-semibold tracking-wide uppercase">
              소개
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              고려대학교 동아리연합회는
            </span>
          </h1>
          <p className="mt-8 text-gray-500 leading-8 indent-2">
            정의와 진리, 그리고 자유의 대학정신을 수호하는 것은 대학인의 영원한
            사명이며, 이를 침해하려는 내외의 모든 불의에 항거하는 것은 양도할 수
            없는 우리의 권리이자 의무이다.
          </p>
        </div>
        <div className="mt-6 prose prose-red prose-lg text-gray-500 mx-auto">
          <p className="indent-2">
            고려대학교는 일제강점기부터 식민지 제국주의 압제에 맞서 싸워 온
            민족의 대학이었으며, 독재정권의 부당한 권위에 저항하여 민주주의를
            외쳤던 4.18 의거와 4.19 혁명, 6월 항쟁을 선도한 유서 깊은 대학이다.
          </p>
          <p className="indent-2">
            우리의 전신인 써클연합회는 과거 민주화운동과 학원자율화운동에서
            주도적으로 시대의 변화를 이끌었다. 한 세기가 마무리되고 21세기에
            들어 동아리연합회는 사회적으로 지배적인 문화를 넘어 모든 생각과
            실천을 포괄하는 자립적인 대학문화를 형성하고, 자유롭고 자주적인
            학생자치를 수호하며, 모든 주체에 대한 어떠한 차별도 없이 공존하는
            대안공동체의 확장을 위하여 역사적 사명을 다하고 있다.
          </p>
          <p className="indent-2">
            모람, 동아리, 동아리연합회로 확장되는 우리의 역할을 다짐하면서,
            민주적이고 자율적인 본 회의 근간을 마련하기 위하여 1984년 4월 16일
            제정되고 여러 차례 개정된 동아리연합회칙을 동아리연합회대표자회의
            의결을 거쳐 개정한다.
          </p>
          <p className="text-right">-고려대학교 동아리연합회 회칙 전문 中-</p>
          <p className="indent-2 mt-20">
            동아리연합회는 학생운동이 활발하던 시절 "서클연합"이라는 이름으로
            학내의 여러 서클들의 연합체로서 시작하였습니다(1984년). 초기에는
            학생운동의 중추적인 역할을 많이 하였으며, 민주화 이후 대학문화를
            위한 공동체로 변화해왔습니다. 현재 동아리연합회는 "문화단대"라는
            별칭을 가진 고려대학교의 문화를 책임지는 단위로 자리 잡았습니다.
          </p>
          <p className="indent-2">
            동아리는 일반적으로 공통의 관심사를 갖고, 그에 대한 활동을
            능동적으로 수행해 나가는 사람들로 구성되어 있으며, 이는 다른
            학생회와 동아리의 두드러지는 차이점입니다. 먼저 중앙동아리란
            고려대학교 안암 캠퍼스의 동아리 중 본회에 정식 등록된 동아리를
            칭합니다. 또한 중앙동아리의 구성원으로서 본회에 등록된 자는 본회의
            정회원이 되며 정회원은 동아리연합회칙에 따르는 의무를 지닙니다.
            본회의 가등록된 동아리의 회원은 본회의 준회원이 되며 이 또한 회칙에
            명시된 회원의 의무를 따릅니다.
          </p>
        </div>
      </div>
    </div>
  );
}