import { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import request from "api/http-request";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import moment from "moment";
import "moment/locale/ko";

function getDateString(date) {
  const postDate = moment(date);
  if (moment().diff(postDate) > 1000 * 60 * 60 * 24) {
    return postDate.format("yyyy-MM-DD");
  } else {
    return postDate.fromNow();
  }
}

export default function Home({ headerHeight }) {
  const [regularNotices, setRegularNotices] = useState([]);
  const [termNotices, setTermNotices] = useState([]);

  useEffect(() => {
    (async () => {
      const regularResult = await request({
        method: "GET",
        url: "/notice-regular",
      });

      if (regularResult && regularResult.success) {
        setRegularNotices(regularResult.notice_regular.posts.slice(0, 3));
      } else {
        toast(
          <div>
            <h3 className="font-bold">오류가 발생했습니다.</h3>
            <p>{`${regularResult.error.code} - ${regularResult.error.message}`}</p>
          </div>,
          { type: "error" }
        );
      }

      const termResult = await request({
        method: "GET",
        url: "/notice-term",
      });

      if (termResult && termResult.success) {
        const exposurePostLenght =
          termResult.notice_term.exposure_post.posts.slice(0, 3).length;
        if (exposurePostLenght < 3) {
          setTermNotices([
            ...termResult.notice_term.exposure_post.posts.slice(0, 3),
            ...termResult.notice_term.closed_post.posts.slice(
              0,
              3 - exposurePostLenght
            ),
          ]);
        } else {
          setTermNotices(
            termResult.notice_term.exposure_post.posts.slice(0, 3)
          );
        }
      } else {
        toast(
          <div>
            <h3 className="font-bold">오류가 발생했습니다.</h3>
            <p>{`${termResult.error.code} - ${termResult.error.message}`}</p>
          </div>,
          { type: "error" }
        );
      }
    })();
  }, []);

  return (
    <div
      className="bg-white"
      style={{
        height: `calc(max(100vh, 720px) - ${headerHeight}px - 0.25rem)`,
      }}
    >
      <div
        className="relative bg-gray-800"
        style={{ height: "calc(100% + 0.25rem)" }}
      >
        <div className="absolute inset-0">
          <video
            autoPlay
            loop
            muted
            playsInline
            src="/main_movie_kucu.mp4"
            className="w-full h-full object-cover"
            poster="/main_photo_kucu.png"
          />
          <div
            className="absolute h-full inset-0 bg-gray-400 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="flex flex-col h-full max-w-7xl mx-auto px-6 items-center justify-center">
          <div className="flex pb-16 relative items-center justify-center">
            <div>
              <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl md:tracking-tight lg:text-6xl lg:tracking-tight">
                고려대학교 동아리연합회
              </h1>
              <p className="mt-6 max-w-3xl text-xl text-gray-300">
                "끝내는 한길에 하나가 되리"&nbsp;&nbsp;―&nbsp;&nbsp;고려대학교의
                대학문화를 책임지는 동아리연합회입니다.
              </p>
            </div>
          </div>
          <section
            className="flex flex-wrap relative justify-center z-10 lg:space-x-8 lg:flex-nowrap w-full"
            aria-labelledby="contact-heading"
          >
            {regularNotices.length !== 0 && (
              <Transition
                show={regularNotices.length !== 0}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="flex flex-col w-full mb-8 bg-white rounded-2xl shadow-xl lg:w-1/2 lg:mb-0">
                  <div className="px-6 py-4 border-b border-gray-200 lg:px-8">
                    <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap">
                      <div className="ml-4 mt-2">
                        <Link
                          to="/notice-regular"
                          className="text-xl leading-6 font-medium text-gray-900"
                        >
                          상시공지
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap pt-4 pb-4 h-32">
                    {regularNotices.map((notice) => {
                      return (
                        <Link
                          to={`/notice-regular/${notice.id}`}
                          className="w-full h-8 flex justify-between text-lg px-6 hover:bg-gray-100 md:px-8"
                          key={notice.id}
                        >
                          <div className="truncate pr-4">{notice.title}</div>
                          <div className="whitespace-nowrap">{getDateString(notice.written_at)}</div>
                        </Link>
                      );
                    })}
                  </div>
                  <div className="p-4 pl-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                    <Link
                      to="/notice-regular"
                      className="text-base font-medium text-rose-700 hover:text-rose-600"
                    >
                      이동<span aria-hidden="true">&nbsp;&rarr;</span>
                    </Link>
                  </div>
                </div>
              </Transition>
            )}

            {termNotices.length !== 0 && (
              <Transition
                show={termNotices.length !== 0}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="flex flex-col w-full bg-white rounded-2xl shadow-xl lg:w-1/2">
                  <div className="px-6 py-4 border-b border-gray-200 lg:px-8">
                    <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap">
                      <div className="ml-4 mt-2">
                        <Link
                          to="/notice-term"
                          className="text-xl leading-6 font-medium text-gray-900"
                        >
                          기간공지
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap pt-4 pb-4 h-32">
                    {termNotices.map((notice) => {
                      return (
                        <Link
                          to={`/notice-term/${notice.id}`}
                          className="w-full h-8 flex justify-between text-lg px-6 hover:bg-gray-100 md:px-8"
                          key={notice.id}
                        >
                          <div className="truncate pr-4">{notice.title}</div>
                          <div className="whitespace-nowrap">{getDateString(notice.written_at)}</div>
                        </Link>
                      );
                    })}
                  </div>
                  <div className="p-4 pl-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                    <Link
                      to="/notice-term"
                      className="text-base font-medium text-rose-700 hover:text-rose-600"
                    >
                      이동<span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </div>
                </div>
              </Transition>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}
