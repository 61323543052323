import '@toast-ui/editor/dist/toastui-editor-viewer.css';

import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import request from 'api/http-request';
import moment from 'moment';
import { toast } from 'react-toastify';
import PageHead from 'Components/PageHead';
import { Viewer } from '@toast-ui/react-editor';

const boardName = '서류제출';
const boardBaseUrl = '/document';
const boardDescription = '재등록서약서 등 주요 서류를 제출할 수 있습니다';

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export default function Document() {
  const params = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const page = location.state?.page ?? 1;

  const viewerRef = useRef();

  const [ document, setDocument ] = useState({});

  const documentId = params.id;

  // 날짜 표시 함수
  const formatDate = (written_at) => {
    const written = moment(written_at);

    return written.format('yyyy-MM-DD H:mm:ss');
  }

  useEffect(() => {
    (async () => {
      const url = `${boardBaseUrl}/${documentId}`;
      const result = await request({
        method: "GET",
        url,
      });

      if (result && result.success) {
        viewerRef.current.getInstance().setMarkdown(result.document.contents);
        setDocument(result.document);
      } else {
        toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
      }
    })();
  }, [documentId])

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div className="w-full">
        <PageHead
          title={boardName}
          description={boardDescription}
          to={boardBaseUrl}
        />
        <div className="bg-white shadow overflow-hidden rounded-md mb-3">
          <div className="px-4 py-5 border-b border-gray-200 lg:px-8">
            <div className="-ml-4 -mt-2 flex items-center flex-wrap">
              <div className="w-full ml-4 mt-2">
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                  {document.title}
                </h3>
              </div>
              <div className="w-full ml-4 flex justify-between mt-1 text-sm font-medium text-gray-500">
                <div>{document.name}</div>
                <div>{formatDate(document.written_at)}</div>
              </div>
            </div>
          </div>
          <div className="py-4 px-4 border-b border-gray-200 text-lg-wrapper lg:py-6 lg:px-8">
            <Viewer ref={viewerRef} />
          </div>
        </div>

        {document.files?.length !== 0 && (
          <div className="bg-white shadow overflow-hidden rounded-md">
            <div className="px-4 py-3 border-b border-gray-200 lg:px-8">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap">
                <div className="ml-4 mt-2">
                  <h3 className="leading-6 font-bold text-gray-900">
                    첨부파일
                  </h3>
                </div>
              </div>
            </div>
            <div className="divide-y">
              {document.files?.map((file) => (
                <a
                  className="flex px-4 py-5 items-center justify-between hover:bg-gray-200 lg:px-8 lg:justify-start"
                  href={`https://api.club.ku.ster.ws${file.link}`}
                  target="_blank"
                  rel="noreferrer"
                  key={file.id}
                >
                  <p className="w-3/4 text-sm font-medium text-gray-900 truncate lg:w-auto">
                    {file.name}
                  </p>
                  <p className="text-right text-sm font-base text-gray-600">
                    &nbsp;({formatBytes(file.size)})
                  </p>
                </a>
              ))}
            </div>
          </div>
        )}

        <div className="flex justify-between my-4">
          <div>
            <button
              type="button"
              className="inline-flex items-center mr-2 px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-gray-500 hover:bg-gray-600"
              onClick={() => navigate(`${boardBaseUrl}?page=${page}`)}
            >
              목록으로
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}