import React, { useEffect, useState } from 'react';
import request from 'api/http-request';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { KeyIcon } from '@heroicons/react/solid';
import jwtDecode from 'jwt-decode';

export default function ChangePhone({ setToken }) {
  const navigate = useNavigate();
  const [ oldPhone, setOldPhone ] = useState('');
  const [ phoneMiddle, setPhoneMiddle ] = useState('');
  const [ phoneLast, setPhoneLast ] = useState('');
  
  // 전화번호 변경 요청
  const phoneChangeAction = async () => {
    if (!phoneMiddle || !phoneLast) return toast('전화번호를 입력해주세요.', {type: 'error'});

    const userEmail = jwtDecode(localStorage.getItem('token')).email;

    const result = await request({
      method: 'PATCH',
      url: `/user/${userEmail}/phone`,
      data: {
        phone: `010-${phoneMiddle}-${phoneLast}`,
      },
    });

    if (result && result.success) {
      setOldPhone(`010-${phoneMiddle}-${phoneLast}`);
      toast('성공적으로 전화번호를 등록했습니다.', {type: 'success'});
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  const deletePhoneAction = async () => {
    const userEmail = jwtDecode(localStorage.getItem('token')).email;

    const result = await request({
      method: 'DELETE',
      url: `/user/${userEmail}/phone`
    });

    if (result && result.success) {
      setOldPhone('');
      setPhoneMiddle('');
      setPhoneLast('');
      toast('성공적으로 전화번호를 삭제했습니다.', {type: 'success'});
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  useEffect(() => {
    (async () => {
      const userEmail = jwtDecode(localStorage.getItem('token')).email;

      const result = await request({
        method: 'GET',
        url: `/user/${userEmail}/phone`
      });

      const phone = result.phone;

      if (phone && phone.includes('-')) {
        setOldPhone(phone);

        const phoneArray = phone.split('-');
        setPhoneMiddle(phoneArray[1]);
        setPhoneLast(phoneArray[2]);
      }
    })();
  }, [])

  return (
    <div
      className="flex items-center justify-center px-6 lg:px-0"
      style={{ minHeight: "calc(100vh - 64px)" }}
    >
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-bold text-gray-900">
            전화번호 등록
          </h2>
        </div>
        <div className="mt-8 space-y-6">
          <div>
            <div className="rounded-md -space-y-px">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  전화번호
                </label>
                <div className="mt-1 flex rounded-md">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <label
                      className="bg-gray-50 px-3 py-2 block w-full rounded-md sm:text-sm text-center border border-gray-300"
                    >010</label>
                    <label
                      className="px-3 py-2 block rounded-md sm:text-sm text-center"
                    >-</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md sm:text-sm text-center border-gray-300"
                      placeholder="0000"
                      pattern="[0-9]{3,4}"
                      inputMode="numeric"
                      value={phoneMiddle}
                      onChange={(e) => {
                        if (e.target.value.length <= 4) {
                          setPhoneMiddle(e.target.value);
                        }
                      }}
                    />
                    <label
                      className="px-3 py-2 block rounded-md sm:text-sm text-center"
                    >-</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md sm:text-sm text-center border-gray-300"
                      placeholder="0000"
                      pattern="[0-9]{4}"
                      inputMode="numeric"
                      value={phoneLast}
                      onChange={(e) => {
                        if (e.target.value.length <= 4) {
                          setPhoneLast(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="pt-2">
              <button
                className={`bg-indigo-700 hover:bg-indigo-800 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white`}
                onClick={() => { phoneChangeAction(); }}
              >
                {oldPhone ? '전화번호 수정' : '전화번호 등록'}
              </button>
            </div>
            {oldPhone && <div className="pt-2">
              <button
                className={`bg-red-700 hover:bg-red-800 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white`}
                onClick={() => { deletePhoneAction(); }}
              >
                전화번호 삭제
              </button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};