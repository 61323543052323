/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SelectorIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import { Transition, Listbox } from "@headlessui/react";
import moment from "moment";
import "moment/locale/ko";
moment.locale("ko");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MonthCalendar({
  days,
  currentDate,
  rentableDate,
  rooms,
  roomId,
  setCurrentDate,
  setSearchParams,
  setRoomId,
}) {
  const onClickDate = (date) => {
    setCurrentDate(date);
    setSearchParams({ view: "day" });
  };

  const onClickBtn = (next) => {
    if (next === true) {
      setCurrentDate(moment(currentDate).add(1, "months").format("YYYY-MM-DD"));
    } else if (next === false) {
      setCurrentDate(
        moment(currentDate).subtract(1, "months").format("YYYY-MM-DD")
      );
    } else {
      setCurrentDate(moment().format("YYYY-MM-DD"));
    }
  };

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <header className="relative z-20 flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none">
        <h1 className="text-lg font-semibold text-gray-900">
          <time dateTime={currentDate}>
            {moment(currentDate).format("YYYY[년] M[월]")}
          </time>
        </h1>
        <div className="flex items-center justify-center space-x-4">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch">
            <button
              type="button"
              onClick={() => onClickBtn(false)}
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white h-10 py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">이전 달</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={onClickBtn}
              className="hidden border-t border-b border-gray-300 bg-white h-10 px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              이번 달
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={() => onClickBtn(true)}
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white h-10 py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">다음 달</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="w-max">
            <Listbox value={roomId} onChange={setRoomId}>
              {({ open }) => (
                <>
                  <div className="relative">
                    <Listbox.Button className="bg-white relative h-10 w-36 lg:w-36 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm">
                      <span className="block truncate">
                        {rooms.find((room) => room.id === roomId)?.room}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full z-50 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {rooms.map((room) => (
                          <Listbox.Option
                            key={room.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-rose-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={room.id}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {room.room}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-rose-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
      </header>
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            일<span className="sr-only sm:not-sr-only">요일</span>
          </div>
          <div className="bg-white py-2">
            월<span className="sr-only sm:not-sr-only">요일</span>
          </div>
          <div className="bg-white py-2">
            화<span className="sr-only sm:not-sr-only">요일</span>
          </div>
          <div className="bg-white py-2">
            수<span className="sr-only sm:not-sr-only">요일</span>
          </div>
          <div className="bg-white py-2">
            목<span className="sr-only sm:not-sr-only">요일</span>
          </div>
          <div className="bg-white py-2">
            금<span className="sr-only sm:not-sr-only">요일</span>
          </div>
          <div className="bg-white py-2">
            토<span className="sr-only sm:not-sr-only">요일</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {days.map((day) => (
              <div
                key={day.date}
                onClick={() => onClickDate(day.date)}
                className={classNames(
                  day.isCurrentMonth
                    ? "bg-white hover:bg-rose-100 cursor-pointer"
                    : "bg-gray-100 text-gray-500",
                  "relative py-2 px-3 lg:h-24"
                )}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    day.isToday && "bg-red-600 text-white rounded-full",
                    day.requestable && !day.isToday && "bg-rose-100 rounded-lg",
                    day.drawable && "bg-orange-100 rounded-lg",
                    (day.isToday || day.requestable || day.drawable) && "flex h-6 w-6 items-center justify-center font-bold"
                  )}
                >
                  {day.date.split("-").pop().replace(/^0/, "")}
                </time>
                {day.events.length > 0 && (
                  <ol className="mt-2 text-right">
                    {day.events.slice(0, 2).map((event) => (
                      <li key={event.id} className="-mt-2">
                        <a href={event.href} className="group flex">
                          <p
                            className={`${
                              day.isCurrentMonth ? "" : "text-gray-500"
                            } ${
                              event.is_mine
                                ? "text-rose-600"
                                : event.is_my_club
                                ? "text-indigo-600"
                                : "text-gray-900"
                            } flex-auto truncate font-medium`}
                          >
                            {event.status === "Waiting"
                              ? `(${event.club_name})`
                              : event.club_name}
                          </p>
                        </a>
                      </li>
                    ))}
                    {day.events.length > 2 && (
                      <li className="text-gray-500 -mt-2">
                        + {day.events.length - 2}
                      </li>
                    )}
                  </ol>
                )}
              </div>
            ))}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {days.map((day) => (
              <button
                key={day.date}
                type="button"
                onClick={() => onClickDate(day.date)}
                className={classNames(
                  day.isCurrentMonth
                    ? "bg-white hover:bg-rose-100 cursor-pointer"
                    : "bg-gray-100 text-gray-500",
                  day.isToday && "text-white font-semibold",
                  day.isCurrentMonth && !day.isToday && "text-gray-900",
                  !day.isCurrentMonth && !day.isToday && "text-gray-500",
                  "flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10"
                )}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    "flex h-6 w-6 items-center justify-center rounded-full",
                    day.requestable && !day.isToday && "bg-rose-100",
                    day.drawable && "bg-orange-100",
                    day.isToday && "bg-rose-600",
                    "ml-auto"
                  )}
                >
                  {day.date.split("-").pop().replace(/^0/, "")}
                </time>
                {day.events.length > 0 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap">
                    {day.events.map((event) => (
                      <span
                        key={event.id}
                        className={`${
                          event.is_mine
                            ? "bg-rose-600"
                            : event.is_my_club
                            ? "bg-indigo-600"
                            : "bg-gray-400"
                        } mx-0.5 mb-1 h-1.5 w-1.5 rounded-full`}
                      />
                    ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
