import '@toast-ui/editor/dist/toastui-editor-viewer.css';

import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import request from 'api/http-request';
import moment from 'moment';
import { checkAuthority } from 'api/checkAuthority';
import Modal from 'Components/Modal';
import { toast } from 'react-toastify';
import { ExclamationIcon } from '@heroicons/react/outline';
import { PencilIcon, XIcon } from '@heroicons/react/solid';
import { Tooltip } from 'flowbite-react';
import PageHead from 'Components/PageHead';
import ToastEditor from "Components/Editor";
import { Viewer } from '@toast-ui/react-editor';

const boardName = '자유게시판';
const boardBaseUrl = '/board/1';
const boardDescription = '모람들이 자유롭게 대화할 수 있는 익명 게시판입니다';

export default function FreeBoardPost() {
  const params = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const page = location.state?.page ?? 1;

  const viewerRef = useRef();
  const commentWriteRef = useRef();
  const commentEditRef = useRef();

  const [ post, setPost ] = useState({});
  const [ postDeleteModal, setPostDeleteModal ] = useState(false);
  const [ commentDeleteModal, setCommentDeleteModal ] = useState(false);
  const [ selectedComment, setSelectedComment ] = useState(undefined);
  const [ comments, setComments ] = useState([]);
  const [ comment, setComment ] = useState('');
  const [ commentEdit, setCommentEdit ] = useState('');
  const [ isEdit, setIsEdit ] = useState(false);
  
  const postId = params.id;

  // 날짜 표시 함수
  const formatDate = (written_at, modified_at) => {
    const written = moment(written_at);
    const modified = moment(modified_at);

    if (written.diff(modified) === 0) return written.format('yyyy-MM-DD H:mm:ss');
    return <>{written.format('yyyy-MM-DD H:mm:ss')}<div className="inline-block"><Tooltip content={modified.format('yyyy-MM-DD H:mm:ss')} trigger="hover"><span className="text-xs text-gray-500">&nbsp;(수정됨)</span></Tooltip></div></>;
  }

  const fetchPost = async () => {
    const url = `${boardBaseUrl}/${postId}`
    const result = await request({
      method: 'GET',
      url
    });

    if (result && result.success) {
      viewerRef.current.getInstance().setMarkdown(result.post.contents);
      setComments(result.post.comments);
      delete result.post.comments;
      setPost(result.post);
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  // 게시물 삭제 함수
  const deletePostAction = async () => {
    const result = await request({
      method: 'DELETE',
      url: `${boardBaseUrl}/${postId}`,
    });
    if (result && result.success) {
      setPostDeleteModal(!postDeleteModal);
      navigate(`${boardBaseUrl}?page=${page}`);
    } else {
      setPostDeleteModal(!postDeleteModal);
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  const writeCommentAction = async () => {
    if (!comment || comment === '<p><br></p>') return toast('내용을 입력해주세요.', {type: 'error'});

    const result = await request({
      method: 'POST',
      url: `${boardBaseUrl}/${postId}`,
      data: {
        comment
      }
    })

    if (result && result.success) {
      setComment('');
      fetchPost();
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  const editCommentAction = async () => {
    const result = await request({
      method: 'PUT',
      url: `${boardBaseUrl}/${postId}/${selectedComment.id}`,
      data: {
        comment: commentEdit
      }
    });
    if (result && result.success) {
      setSelectedComment(undefined);
      setCommentEdit('');
      setIsEdit(false);
      fetchPost();
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  const deleteCommentAction = async () => {
    const result = await request({
      method: 'DELETE',
      url: `${boardBaseUrl}/${postId}/${selectedComment.id}`,
    });
    if (result && result.success) {
      setCommentDeleteModal(!commentDeleteModal);
      setSelectedComment(undefined);
      fetchPost();
    } else {
      setCommentDeleteModal(!commentDeleteModal);
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  useEffect(() => {
    (async () => {
      const url = `${boardBaseUrl}/${postId}`;
      const result = await request({
        method: "GET",
        url,
      });

      if (result && result.success) {
        viewerRef.current.getInstance().setMarkdown(result.post.contents);
        setComments(result.post.comments);
        delete result.post.comments;
        setPost(result.post);
      } else {
        toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
      }
    })();
  }, [postId])

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div className="w-full">
        <PageHead
          title={boardName}
          description={boardDescription}
          to={boardBaseUrl}
        />
        <div className="bg-white shadow overflow-hidden rounded-md mb-3">
          <div className="px-4 py-5 border-b border-gray-200 lg:px-8">
          <div className="-ml-4 -mt-2 flex items-center flex-wrap">
              <div className="w-full ml-4 mt-2">
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                  {post.title}
                </h3>
              </div>
              <div className="w-full ml-4 flex justify-between mt-1 text-sm font-medium text-gray-500">
                <div>익명</div>
                <div>
                  {post.written_at &&
                    formatDate(post.written_at, post.modified_at)}
                </div>
              </div>
            </div>
          </div>
          <div className="py-4 px-4 border-b border-gray-200 text-lg-wrapper lg:py-6 lg:px-8">
            <Viewer ref={viewerRef} />
          </div>
        </div>

        <div className="bg-white shadow overflow-hidden rounded-md mb-3">
          <div className="px-4 py-3 border-b border-gray-200 lg:px-8">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap">
              <div className="ml-4 mt-2">
                <h3 className="leading-6 font-bold text-gray-900">댓글</h3>
              </div>
            </div>
          </div>
          <div className="divide-y">
            {comments.length ? (
              comments.map((comment) => (
                <div className="px-4 py-5 lg:px-8" key={comment.id}>
                  <div className="flex flex-wrap justify-between space-y-0.5">
                    <p className="flex-auto w-1/2 text-sm font-base text-gray-500">
                      익명
                    </p>
                    <span className="flex-auto w-1/2 text-right text-sm font-base text-gray-500">
                      {formatDate(comment.written_at, comment.modified_at)}
                    </span>
                    <p
                      className="flex-initial w-10/12 text-lg-wrapper text-link-wrapper text-gray-800"
                      dangerouslySetInnerHTML={{ __html: comment.comment }}
                    />
                    <span className="flex-auto flex space-x-2 justify-end items-baseline text-gray-600">
                      {comment.is_mine && (
                        <PencilIcon
                          className="h-4 w-4 mt-1 cursor-pointer"
                          onClick={() => {
                            setSelectedComment(comment);
                            setCommentEdit(comment.comment);
                            setIsEdit(true);
                          }}
                        />
                      )}
                      {(checkAuthority("관리자") || comment.is_mine) && (
                        <XIcon
                          className="h-4 w-4 mt-1 cursor-pointer"
                          onClick={() => {
                            setSelectedComment(comment);
                            setCommentDeleteModal(!commentDeleteModal);
                          }}
                        />
                      )}
                    </span>

                    {(isEdit && (selectedComment.id === comment.id)) && (
                      <div className="flex-auto w-full pt-4">
                        <ToastEditor
                          contents={commentEdit}
                          setContents={setCommentEdit}
                          commentBtnRef={commentEditRef}
                        />
                        <div
                          className="flex justify-end relative bottom-14 z-20 -mb-8"
                          ref={commentEditRef}
                        >
                          <button
                            type="button"
                            onClick={editCommentAction}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600"
                          >
                            댓글 수정
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center text-gray-500 my-6">
                작성된 댓글이 없습니다.
              </div>
            )}
          </div>
        </div>

        <div className="">
          <ToastEditor
            contents={comment}
            setContents={setComment}
            commentBtnRef={commentWriteRef}
          />
          <div
            className="flex justify-end relative bottom-14 z-20 -mb-6"
            ref={commentWriteRef}
          >
            <button
              type="button"
              onClick={writeCommentAction}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600"
            >
              댓글 작성
            </button>
          </div>
        </div>

        <div className="flex justify-between my-4">
          <div>
            <button
              type="button"
              className="inline-flex items-center mr-2 px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-gray-500 hover:bg-gray-600"
              onClick={() => navigate(`${boardBaseUrl}?page=${page}`)}
            >
              목록으로
            </button>
          </div>
          {(checkAuthority("관리자") || post.is_mine) && (
            <div>
              {post.is_mine && (
                <button
                  type="button"
                  className="inline-flex items-center mr-2 px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-rose-600 hover:bg-red-700"
                  onClick={() =>
                    navigate(`${boardBaseUrl}/edit/${postId}`, {
                      state: { page },
                    })
                  }
                >
                  수정
                </button>
              )}
              <button
                type="button"
                onClick={() => setPostDeleteModal(!postDeleteModal)}
                className="inline-flex items-center px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-rose-600 hover:bg-rose-700"
              >
                삭제
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="정말 삭제하시겠습니까?"
        description="정말 삭제하시겠습니까? 삭제한 게시물은 복구할 수 없습니다."
        open={postDeleteModal}
        setOpen={setPostDeleteModal}
        action={deletePostAction}
      >
        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </Modal>
      <Modal
        title="정말 삭제하시겠습니까?"
        description="정말 삭제하시겠습니까? 삭제한 댓글은 복구할 수 없습니다."
        open={commentDeleteModal}
        setOpen={setCommentDeleteModal}
        action={deleteCommentAction}
      >
        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </Modal>
    </div>
  );
}