import jwt_decode from "jwt-decode";

export const isLogin = (giveToken) => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  if (giveToken) return jwt_decode(token);
  return true;
}

export const checkAuthority = (authority) => {
  const token = isLogin({ giveToken: true });
  if (token) {
    if (token.authority.join('').includes(authority)) return true;
  }
  return false;
}