import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import request from 'api/http-request';
import PageHead from 'Components/PageHead';
import { toast } from 'react-toastify';
import ToastEditor from "Components/Editor";
import Dropzone from 'Components/Dropzone';
import moment from 'moment';

const boardName = '기간공지';
const boardBaseUrl = '/notice-term';
const boardDescription = '';

export default function NoticeTermEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [ title, setTitle ] = useState('');
  const [ contents, setContents ] = useState('');
  const [ toDate, setToDate ] = useState('');
  const [ fromDate, setFromDate ] = useState('');
  const [ files, setFiles ] = useState([]);
  const [ isInvaild, setIsInvaild ] = useState(false);

  const postId = params.id;

  useEffect(() => {
    (async () => {
      const result = await request({
        method: 'GET',
        url: `${boardBaseUrl}/${postId}`,
      });
      if (!result || !result.success) {
        toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
        setIsInvaild(true);
      } else {
        setTitle(result.post.title);
        setContents(result.post.contents);
        setFiles(result.post.files);
        setToDate(moment(result.post.exposure_to).format('yyyy-MM-DD'));
        setFromDate(result.post.exposure_from);
      }
    })()
  }, [postId])

  const editAction = async () => {
    if (!title) return toast('제목을 입력해주세요.', {type: 'error'});
    if (!contents) return toast('내용을 입력해주세요.', {type: 'error'});
    if (!toDate) return toast('종료 날짜를 입력해주세요.', {type: 'error'});

    const exposure_to = `${toDate} 23:59:59`;
    const exposure_from = fromDate;

    const result = await request({
      method: 'PUT',
      url: `${boardBaseUrl}/${postId}`,
      data: {
        title,
        contents,
        files,
        exposure_to,
        exposure_from
      },
    });

    if (result && result.success) {
      navigate(`${boardBaseUrl}/${postId}`, {state: location.state});
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'})
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div>
        <PageHead
          title={boardName}
          description={boardDescription}
          to={boardBaseUrl}
        />
        <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
          <div className="basis-full lg:basis-10/12">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              제목
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                className="shadow-sm focus:ring-rose-500 focus:border-rose-500 block w-full border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="basis-full lg:basis-2/12">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              종료 날짜
            </label>
            <div className="mt-1">
              <input
                type="date"
                name="date"
                id="date"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                className="shadow-sm focus:ring-rose-500 focus:border-rose-500 block w-full border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          내용
        </label>
        <ToastEditor contents={contents} setContents={setContents} />
      </div>

      <Dropzone files={files} setFiles={setFiles} className="mt-6" />

      <div className="flex justify-end my-4">
        <button
          type="button"
          onClick={editAction}
          className="inline-flex items-center px-4 py-2 border border-transparent font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600"
          disabled={isInvaild}
        >
          수정
        </button>
      </div>
    </div>
  );
}