import { useState, useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import jwt_decode from "jwt-decode";

import 'react-toastify/dist/ReactToastify.css';

// 헤더
import Header from 'Components/Header';

// 인증 라우트
import PrivateRoute from 'Components/PrivateRoute';
import PublicRoute from 'Components/PublicRoute';

// 라우트
import Home from './Home';
import About from './About';
// 상시공지 라우트
import NoticeRegular from './Notice/Regular';
import NoticeRegularWrite from './Notice/Regular/Write';
import NoticeRegularEdit from './Notice/Regular/Edit';
import NoticeRegularPost from './Notice/Regular/_id';
// 기간공지 라우트
import NoticeTerm from './Notice/Term';
import NoticeTermWrite from './Notice/Term/Write';
import NoticeTermEdit from './Notice/Term/Edit';
import NoticeTermPost from './Notice/Term/_id';
// 자유게시판 라우트
import FreeBoard from './Board/FreeBoard';
import FreeBoardPost from './Board/FreeBoard/_id';
import FreeBoardWrite from './Board/FreeBoard/Write';
import FreeBoardEdit from './Board/FreeBoard/Edit';
// 문의 라우트
import RequestBoard from './Board/Request';
import RequestBoardPost from './Board/Request/_id';
import RequestBoardWrite from './Board/Request/Write';
import RequestBoardEdit from './Board/Request/Edit';
// 시설보고 라우트
import FacilityBoard from './Board/Facility';
import FacilityBoardPost from './Board/Facility/_id';
import FacilityBoardWrite from './Board/Facility/Write';
import FacilityBoardEdit from './Board/Facility/Edit';
// 서류제출 라우트
import DocumentList from './Document';
import Document from './Document/_id';
import DocumentWrite from './Document/Write';

import Rent from './Rent';
import Management from './Management';
import Login from './User/Login';
import Register from './User/Register';
import ResetPassword from './User/ResetPassword';
import ClubSetting from './User/ClubSetting';
import ChangePassword from './User/ChangePassword';
import ChangePhone from './User/ChangePhone';

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

export default function IndexRouter() {
  const [ token, setToken ] = useState("");
  const [ headerHeight, setHeaderHeight ] = useState("0px");

  // Token 만료 체크
  useEffect(() => {
    const tokenLocalStorage = localStorage.getItem('token');
    if (tokenLocalStorage) {
      const decoded_token = jwt_decode(tokenLocalStorage);
      if (Date.now() > decoded_token.exp * 1000) {
        localStorage.removeItem('token');
      } else {
        setToken(tokenLocalStorage);
      }
    }
  }, []);

  const routes = [
    {path: "/", element: <PublicRoute><Home headerHeight={headerHeight} /></PublicRoute>},
    {path: "/about", element: <PublicRoute><About /></PublicRoute>},
    // 상시공지 라우트
    {path: "/notice-regular", element: <PublicRoute><NoticeRegular /></PublicRoute>},
    {path: "/notice-regular/write", element: <PrivateRoute authority="관리자"><NoticeRegularWrite /></PrivateRoute>},
    {path: "/notice-regular/edit/:id", element: <PrivateRoute authority="관리자"><NoticeRegularEdit /></PrivateRoute>},
    {path: "/notice-regular/:id", element: <PublicRoute><NoticeRegularPost /></PublicRoute>},
    // 기간공지 라우트
    {path: "/notice-term", element: <PublicRoute><NoticeTerm /></PublicRoute>},
    {path: "/notice-term/write", element: <PrivateRoute authority="관리자"><NoticeTermWrite /></PrivateRoute>},
    {path: "/notice-term/edit/:id", element: <PrivateRoute authority="관리자"><NoticeTermEdit /></PrivateRoute>},
    {path: "/notice-term/:id", element: <PublicRoute><NoticeTermPost /></PublicRoute>},
    // 자유게시판 라우트
    {path: "/board/1", element: <PrivateRoute authority="일반회원"><FreeBoard /></PrivateRoute>},
    {path: "/board/1/:id", element: <PrivateRoute authority="일반회원"><FreeBoardPost /></PrivateRoute>},
    {path: "/board/1/write", element: <PrivateRoute authority="일반회원"><FreeBoardWrite /></PrivateRoute>},
    {path: "/board/1/edit/:id", element: <PrivateRoute authority="일반회원"><FreeBoardEdit /></PrivateRoute>},
    // 문의 라우트
    {path: "/board/2", element: <PrivateRoute authority="일반회원"><RequestBoard /></PrivateRoute>},
    {path: "/board/2/:id", element: <PrivateRoute authority="일반회원"><RequestBoardPost /></PrivateRoute>},
    {path: "/board/2/write", element: <PrivateRoute authority="일반회원"><RequestBoardWrite /></PrivateRoute>},
    {path: "/board/2/edit/:id", element: <PrivateRoute authority="일반회원"><RequestBoardEdit /></PrivateRoute>},
    // 시설보고 라우트
    {path: "/board/3", element: <PrivateRoute authority="일반회원"><FacilityBoard /></PrivateRoute>},
    {path: "/board/3/:id", element: <PrivateRoute authority="일반회원"><FacilityBoardPost /></PrivateRoute>},
    {path: "/board/3/write", element: <PrivateRoute authority="일반회원"><FacilityBoardWrite /></PrivateRoute>},
    {path: "/board/3/edit/:id", element: <PrivateRoute authority="일반회원"><FacilityBoardEdit /></PrivateRoute>},
    // 서류제출 라우트
    {path: "/document", element: <PrivateRoute authority="일반회원"><DocumentList /></PrivateRoute>},
    {path: "/document/:id", element: <PrivateRoute authority="일반회원"><Document /></PrivateRoute>},
    {path: "/document/write", element: <PrivateRoute authority="일반회원"><DocumentWrite /></PrivateRoute>},

    {path: "/rent", element: <PrivateRoute authority="일반회원"><Rent /></PrivateRoute>},
    {path: "/management", element: <PrivateRoute authority="관리자"><Management /></PrivateRoute>},
    {path: "/login", element: <PublicRoute restricted><Login setToken={setToken} /></PublicRoute>},
    {path: "/register", element: <PublicRoute restricted><Register /></PublicRoute>},
    {path: "/reset-password", element: <PublicRoute restricted><ResetPassword /></PublicRoute>},
    {path: "/user/change-password", element: <PrivateRoute authority="일반회원"><ChangePassword setToken={setToken} /></PrivateRoute>},
    {path: "/user/change-phone", element: <PrivateRoute authority="일반회원"><ChangePhone setToken={setToken} /></PrivateRoute>},
    {path: '/user/club', element: <PrivateRoute authority="일반회원"><ClubSetting /></PrivateRoute>},
  ]

  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <div className="min-h-screen h-full">
            <Header
              token={token}
              setToken={setToken}
              setHeaderHeight={setHeaderHeight}
            />
            <Routes>
              {routes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                );
              })}
            </Routes>
            <div className='h-1' />
          </div>
        </Wrapper>
      </BrowserRouter>
      <ToastContainer autoClose={3000} />
    </>
  );
}