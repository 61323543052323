import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ExclamationIcon, CloudUploadIcon, XIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import Modal from './Modal';
import request from 'api/http-request';
import md5 from 'md5';

export default function Dropzone({ className, files, setFiles }) {
  const [ fileRemoveModal, setFileRemoveModal ] = useState(false);
  const [ selectedFileIndex, setSelectedFileIndex ] = useState();

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles) {
      if (rejectedFiles.length > 5) {
        return toast("최대 업로드 파일 수 초과", { type: "error" });
      }
      for (const file of rejectedFiles) {
        for (const error of file.errors) {
          if (error.code === "file-too-large")
            return toast("최대 업로드 파일 용량 초과", { type: "error" });
        }
      }
    }
    
    acceptedFiles.map(async (file) => {
      const form = new FormData();
      form.append("file", file, md5(file.name));
      form.append("name", encodeURIComponent(file.name));

      const result = await request({
        method: "POST",
        url: "/file/upload",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: form,
      });

      if (result && result.success) {
        return setFiles((current) => [...current, result.file]);
      } else {
        return toast(
          <div>
            <h3 className="font-bold">오류가 발생했습니다.</h3>
            <p>{`${result.error.code} - ${result.error.message}`}</p>
          </div>,
          { type: "error" }
        );
      }
    });
  }, [setFiles]);

  const fileValidator = (file) => {
    if (file.name && files.length >= 5)
      return toast("최대 업로드 파일 수 초과", { type: "error" });

    return null;
  }

  const removeFile = () => {
    setFiles((current) =>
      current.filter((_, i) => {
        return selectedFileIndex !== i;
      })
    );
    setFileRemoveModal(false);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    maxSize: 1 * 1024 * 1024 * 1024,
    validator: fileValidator,
    onDrop,
  });

  return (
    <div className={className}>
      <div
        className="flex justify-center items-center w-full mb-2"
        {...getRootProps()}
      >
        <div
          htmlFor="dropzone-file"
          className="flex flex-col justify-center items-center w-full h-32 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-200"
        >
          <div className="flex flex-col justify-center items-center pt-5 pb-6">
            <CloudUploadIcon className="mb-2 w-8 h-8 text-gray-400" />
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">
                첨부파일을 드래그 앤 드롭하여 업로드
              </span>
              &nbsp;또는 파일 선택
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              (최대 5개, 각 파일 당 1024MB)
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            {...getInputProps()}
          />
        </div>
      </div>
      {files.length > 0 && (
        <>
          <div className="flex flex-wrap space-y-2 text-gray-700">
            {files.map((file, index) => (
              <div className="p-4 flex w-full items-center rounded-lg border-2 border-gray-300 border-dashed lg:justify-between" key={index}>
                <span className="w-auto truncate font-medium lg:w-auto">{file.name}&nbsp;</span>
                <small className="flex-initial w-1/2">({formatBytes(file.size)})</small>
                <span
                  className="flex-auto flex justify-end cursor-pointer"
                  onClick={() => {
                    setSelectedFileIndex(index);
                    setFileRemoveModal(!fileRemoveModal);
                  }}
                >
                  <XIcon className="w-4 h-4" />
                </span>
              </div>
            ))}
          </div>
          <Modal
            title="정말 삭제하시겠습니까?"
            description="정말 삭제하시겠습니까? 삭제한 첨부파일은 복구할 수 없습니다."
            open={fileRemoveModal}
            setOpen={setFileRemoveModal}
            action={removeFile}
          >
            <ExclamationIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </Modal>
        </>
      )}
    </div>
  );
}