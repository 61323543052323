import "Styles/calendar.css";
import { Fragment, useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SelectorIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { Transition, Listbox, Dialog } from "@headlessui/react";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import WaitingModal from "Components/WaitingModal";
import { checkAuthority } from "api/checkAuthority";
import request from "api/http-request";
import moment from "moment";
import "moment/locale/ko";
moment.locale("ko");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Modal = ({
  open,
  setOpen,
  currentDate,
  roomId,
  getDays,
  isAvailable,
}) => {
  const [clubs, setClubs] = useState([]);
  const [clubId, setClubId] = useState(null);
  const [clubName, setClubName] = useState("");
  const [startTime, setStartTime] = useState(720);
  const [period, setPeriod] = useState(120);
  const [availableTime, setAvailableTime] = useState(10);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (clubId === 0) return setClubName("");
    if (clubId !== null)
      setClubName(clubs.find((club) => club.id === clubId).name);
  }, [clubs, clubId]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    decodedToken.club.push({
      id: 0,
      name: "기타",
      division: {
        id: 0,
        name: "기타",
      },
    });
    if (checkAuthority("대관우선")) {
      setAvailableTime(48);
    } else {
      setAvailableTime(10);
    }
    setClubs(decodedToken.club);
    setClubId(decodedToken.club[0].id);
  }, []);

  const rentAction = async () => {
    if (!clubId && !clubName) {
      return toast("동아리 이름을 입력해주세요.", { type: "error" });
    }
    if (!agree) {
      return toast("대관 사용 규칙에 동의해주세요.", { type: "error" });
    }
    const rentTimeStart = moment(currentDate)
      .hour(Math.trunc(startTime / 60))
      .minute(startTime % 60)
      .second(0)
      .format("YYYY-MM-DD HH:mm:ss");
    const rentTimeEnd = moment(rentTimeStart)
      .add(period, "minutes")
      .subtract(1, "seconds")
      .format("YYYY-MM-DD HH:mm:ss");
    const result = await request({
      method: "POST",
      url: `/rent/${roomId}`,
      data: {
        clubId,
        clubName,
        rentTimeStart,
        rentTimeEnd,
      },
    });

    if (result && result.success) {
      toast(
        `대관 ${isAvailable === "Draw" ? "예약" : "신청"}에 성공하였습니다.`,
        { type: "success" }
      );
      getDays();
      setOpen(false);
    } else {
      toast(
        <div>
          <h3 className="font-bold">오류가 발생했습니다.</h3>
          <p>{`${result.error.code} - ${result.error.message}`}</p>
        </div>,
        { type: "error" }
      );
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:p-6">
                <div className="flex-row sm:items-start">
                  <div className="mt-3 mx-3 lg:mx-0 lg:mt-0">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {isAvailable === "Draw" ? "대관 예약" : "대관 신청"}
                    </Dialog.Title>
                    <div className="mt-2 text-sm text-gray-500">
                      <div className="flex flex-wrap space-y-4 lg:flex-row">
                        <div className="w-full">
                          <label className="mb-1 block text-sm font-medium text-gray-700">
                            날짜
                          </label>
                          <input
                            type="text"
                            className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
                            defaultValue={currentDate}
                            disabled={true}
                          />
                        </div>

                        <div className="w-full">
                          <Listbox value={clubId} onChange={setClubId}>
                            {({ open }) => (
                              <>
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  동아리
                                </Listbox.Label>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm lg:h-10">
                                    <span className="block truncate">
                                      {
                                        clubs.find((club) => club.id === clubId)
                                          ?.name
                                      }
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {clubs.map((club) => (
                                        <Listbox.Option
                                          key={`club_${club.id}`}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-rose-600"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={club.id}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {club.name}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-rose-600",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                        {clubId === 0 && (
                          <div className="w-full">
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              동아리 이름
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="clubName"
                                value={clubName}
                                onChange={(e) => {
                                  setClubName(e.target.value);
                                }}
                                className="shadow-sm focus:ring-rose-500 focus:border-rose-500 block w-full border-gray-300 rounded-md"
                              />
                            </div>
                          </div>
                        )}

                        <div className="w-full lg:w-1/2 lg:pr-2">
                          <Listbox value={startTime} onChange={setStartTime}>
                            {({ open }) => {
                              const h = Math.trunc(startTime / 60);
                              const lM = startTime % 60;
                              return (
                                <>
                                  <Listbox.Label className="block text-sm font-medium text-gray-700">
                                    대관 시작 시각
                                  </Listbox.Label>
                                  <div className="mt-1 relative">
                                    <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm">
                                      <span className="block truncate">
                                        {`${h < 12 ? "오전 " : "오후 "}
                                  ${
                                    h > 0
                                      ? `${h % 12 === 0 ? 12 : h % 12}시 `
                                      : "12시 "
                                  }${lM > 0 ? `${lM}분` : ""}`}
                                      </span>
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        {Array(48)
                                          .fill(null)
                                          .map((_, i) => {
                                            const minute = i * 30;
                                            const hour = Math.trunc(
                                              minute / 60
                                            );
                                            const leftMinute = minute % 60;
                                            return (
                                              <Listbox.Option
                                                key={`start_${minute}`}
                                                className={({ active }) =>
                                                  classNames(
                                                    active
                                                      ? "text-white bg-rose-600"
                                                      : "text-gray-900",
                                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                                  )
                                                }
                                                value={minute}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <span
                                                      className={classNames(
                                                        selected
                                                          ? "font-semibold"
                                                          : "font-normal",
                                                        "block truncate"
                                                      )}
                                                    >
                                                      {`${
                                                        hour < 12
                                                          ? "오전 "
                                                          : "오후 "
                                                      }
                                                    ${
                                                      hour > 0
                                                        ? `${
                                                            hour % 12 === 0
                                                              ? 12
                                                              : hour % 12
                                                          }시 `
                                                        : "12시 "
                                                    }${
                                                        leftMinute > 0
                                                          ? `${leftMinute}분`
                                                          : ""
                                                      }`}
                                                    </span>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active
                                                            ? "text-white"
                                                            : "text-rose-600",
                                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                      >
                                                        <CheckIcon
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            );
                                          })}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              );
                            }}
                          </Listbox>
                        </div>

                        <div className="w-full lg:w-1/2">
                          <Listbox value={period} onChange={setPeriod}>
                            {({ open }) => (
                              <>
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  대관 기간
                                </Listbox.Label>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm">
                                    <span className="block truncate">
                                      {`${
                                        Math.trunc(period / 60) > 0
                                          ? `${Math.trunc(period / 60)}시간 `
                                          : ""
                                      }${
                                        period % 60 > 0
                                          ? `${period % 60}분`
                                          : ""
                                      }`}
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {Array(availableTime)
                                        .fill(null)
                                        .map((_, i) => {
                                          const minute = (i + 1) * 30;
                                          const hour = Math.trunc(minute / 60);
                                          const leftMinute = minute % 60;
                                          return (
                                            <Listbox.Option
                                              key={`period_${minute}`}
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? "text-white bg-rose-600"
                                                    : "text-gray-900",
                                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                                )
                                              }
                                              value={minute}
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <span
                                                    className={classNames(
                                                      selected
                                                        ? "font-semibold"
                                                        : "font-normal",
                                                      "block truncate"
                                                    )}
                                                  >
                                                    {`${
                                                      hour > 0
                                                        ? `${hour}시간 `
                                                        : ""
                                                    }${
                                                      leftMinute > 0
                                                        ? `${leftMinute}분`
                                                        : ""
                                                    }`}
                                                  </span>

                                                  {selected ? (
                                                    <span
                                                      className={classNames(
                                                        active
                                                          ? "text-white"
                                                          : "text-rose-600",
                                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                                      )}
                                                    >
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          );
                                        })}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full mx-3 mt-4 flex items-center justify-between lg:mx-0">
                  <div className="flex items-center">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300 rounded"
                      checked={agree}
                      onChange={() => setAgree(!agree)}
                    />
                    <label
                      htmlFor="terms"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      <span title="본인 및 본 동아리는 대관한 장소를 약속한 시간에 맞춰 사용할 것이고, 사용 후 청결하게 청소하고 사용 전과 같이 정돈하며, 만약 대관 중 설비를 파손 또는 분실할 때에는 금전적 책임을 질 것임을 서약합니다.">
                        대관 사용 규칙에 관한 동의
                      </span>
                    </label>
                  </div>
                </div>

                <div className="mt-5 mx-3 sm:mt-4 sm:flex sm:flex-row-reverse lg:mx-0">
                  <button
                    type="button"
                    onClick={rentAction}
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 sm:ml-3 sm:w-auto sm:text-sm`}
                  >
                    신청
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    취소
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default function DayCalendar({
  days,
  currentDate,
  rentableDate,
  rooms,
  roomId,
  setCurrentDate,
  setRoomId,
  getDays,
}) {
  const [searchParams, setSearchParams] = useSearchParams({ view: "day" });
  const [rentModal, setRentModal] = useState(false);
  const [waitingModal, setWaitingModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isAvailable, setIsAvailable] = useState("Unavailable");

  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);

  const onClickBtn = (next) => {
    if (next === true) {
      setCurrentDate(moment(currentDate).add(1, "days").format("YYYY-MM-DD"));
    } else if (next === false) {
      setCurrentDate(
        moment(currentDate).subtract(1, "days").format("YYYY-MM-DD")
      );
    } else {
      setCurrentDate(moment().format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;

    if (rentableDate.request || rentableDate.draw) {
      if (
        moment(currentDate).diff(
          moment(rentableDate.request.start).startOf("day")
        ) >= 0 &&
        moment(currentDate).diff(
          moment(rentableDate.request.end).endOf("day")
        ) <= 0
      ) {
        setIsAvailable("Request");
      } else if (
        rentableDate.draw?.start && 
        moment(currentDate).diff(
          moment(rentableDate.draw.start).startOf("day")
        ) >= 0 &&
        moment(currentDate).diff(moment(rentableDate.draw.end).endOf("day")) <=
          0
      ) {
        setIsAvailable("Draw");
      } else {
        setIsAvailable("Unavailable");
      }
    }
  }, [rentableDate, currentDate]);

  return (
    <div className="flex h-full flex-col">
      <header className="relative z-20 flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
        <div>
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            <time dateTime={currentDate} className="inline">
              {moment(currentDate).format("YYYY[년] M[월] D[일]")}
            </time>
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            {moment(currentDate).format("dddd")}
          </p>
        </div>
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => setSearchParams({ view: "month" })}
            className="hidden focus:outline-none ml-6 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-600 lg:block"
          >
            월별 보기
          </button>
          <div className="hidden items-center rounded-md shadow-sm md:ml-4 md:items-stretch md:flex">
            <button
              type="button"
              onClick={() => onClickBtn(false)}
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">어제</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={() => onClickBtn("today")}
              className="border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative"
            >
              오늘
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={() => onClickBtn(true)}
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">내일</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          <div className="flex md:ml-4 md:items-center">
            <Listbox value={roomId} onChange={setRoomId}>
              {({ open }) => (
                <>
                  <div className="relative">
                    <Listbox.Button className="bg-white relative h-10 w-32 lg:w-32 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm">
                      <span className="block truncate">
                        {rooms.find((room) => room.id === roomId)?.room}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full z-50 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {rooms.map((room) => (
                          <Listbox.Option
                            key={`room_${room.id}`}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-rose-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={room.id}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {room.room}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-rose-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
            <div className="hidden ml-6 h-6 w-px bg-gray-300 md:block" />
          </div>
          <button
            type="button"
            onClick={() => {
              const token = localStorage.getItem("token");
              const decodedToken = jwtDecode(token);

              if(decodedToken.phone && decodedToken.phone.includes('-')) {
                setRentModal(true);
              } else {
                toast("전화번호를 등록한 사용자만 대관 신청 할 수 있습니다.", { type: "error" });
              }
            }}
            disabled={isAvailable === "Unavailable"}
            className={`${
              isAvailable === "Unavailable"
                ? "bg-gray-400"
                : isAvailable === "Request"
                ? "bg-rose-600 hover:bg-rose-700"
                : "bg-orange-500 hover:bg-orange-600"
            } focus:outline-none ml-6 rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm`}
          >
            {isAvailable === "Draw" ? "대관 예약" : "대관 신청"}
          </button>
        </div>
      </header>
      <div className="flex flex-auto overflow-hidden bg-white pb-4">
        <div
          ref={container}
          className="flex flex-auto flex-col overflow-auto"
          style={{ height: `calc(100vh - 300px)` }}
        >
          <div
            ref={containerNav}
            className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden"
          >
            <button
              onClick={() =>
                setCurrentDate(
                  moment(currentDate).subtract(3, "days").format("YYYY-MM-DD")
                )
              }
              type="button"
              className="flex flex-col items-center pt-3 pb-1.5"
            >
              <span>
                {moment(currentDate).subtract(3, "days").format("ddd")}
              </span>
              {/* Default: "text-gray-900", Selected: "bg-gray-900 text-white", Today (Not Selected): "text-indigo-600", Today (Selected): "bg-indigo-600 text-white" */}
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                {moment(currentDate).subtract(3, "days").format("DD")}
              </span>
            </button>
            <button
              onClick={() =>
                setCurrentDate(
                  moment(currentDate).subtract(2, "days").format("YYYY-MM-DD")
                )
              }
              type="button"
              className="flex flex-col items-center pt-3 pb-1.5"
            >
              <span>
                {moment(currentDate).subtract(2, "days").format("ddd")}
              </span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                {moment(currentDate).subtract(2, "days").format("DD")}
              </span>
            </button>
            <button
              onClick={() =>
                setCurrentDate(
                  moment(currentDate).subtract(1, "days").format("YYYY-MM-DD")
                )
              }
              type="button"
              className="flex flex-col items-center pt-3 pb-1.5"
            >
              <span>
                {moment(currentDate).subtract(1, "days").format("ddd")}
              </span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                {moment(currentDate).subtract(1, "days").format("DD")}
              </span>
            </button>
            <button
              type="button"
              className="flex flex-col items-center pt-3 pb-1.5"
            >
              <span>{moment(currentDate).format("ddd")}</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full bg-rose-600 text-base font-semibold text-white">
                {moment(currentDate).format("DD")}
              </span>
            </button>
            <button
              onClick={() =>
                setCurrentDate(
                  moment(currentDate).add(1, "days").format("YYYY-MM-DD")
                )
              }
              type="button"
              className="flex flex-col items-center pt-3 pb-1.5"
            >
              <span>{moment(currentDate).add(1, "days").format("ddd")}</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                {moment(currentDate).add(1, "days").format("DD")}
              </span>
            </button>
            <button
              onClick={() =>
                setCurrentDate(
                  moment(currentDate).add(2, "days").format("YYYY-MM-DD")
                )
              }
              type="button"
              className="flex flex-col items-center pt-3 pb-1.5"
            >
              <span>{moment(currentDate).add(2, "days").format("ddd")}</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                {moment(currentDate).add(2, "days").format("DD")}
              </span>
            </button>
            <button
              onClick={() =>
                setCurrentDate(
                  moment(currentDate).add(3, "days").format("YYYY-MM-DD")
                )
              }
              type="button"
              className="flex flex-col items-center pt-3 pb-1.5"
            >
              <span>{moment(currentDate).add(3, "days").format("ddd")}</span>
              <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                {moment(currentDate).add(3, "days").format("DD")}
              </span>
            </button>
          </div>
          <div className="flex w-full flex-auto">
            <div className="w-14 flex-none bg-white ring-1 ring-gray-50" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1 pl-2 pr-6">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-odd-100 divide-gray-even-200"
                style={{ gridTemplateRows: "repeat(48, minmax(2rem, 1fr))" }}
              >
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    1AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    3AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    5AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    9AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    11AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    1PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    3PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    5PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    9PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    11PM
                  </div>
                </div>
                <div />
                <div className="-row-end-1 h-7">
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12AM
                  </div>
                </div>
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 row-end-2 grid grid-cols-1"
                style={{
                  gridTemplateRows:
                    "1.75rem repeat(288, minmax(0, 1fr)) 1.75rem",
                  gridTemplateColumns: "repeat(10, 1fr)",
                }}
              >
                {days.length > 0 &&
                  days.find((day) => day.date === currentDate) &&
                  days
                    .find((day) => day.date === currentDate)
                    .events.map((event) => {
                      const timeStart = moment(event.time_start);
                      const timeEnd = moment(event.time_end);
                      const startRow =
                        (timeStart.hours() * 60 + timeStart.minutes()) / 5 + 2;
                      const heightRow = event.period / 1000 / 60 / 5;
                      return (
                        <li
                          key={`event_${event.id}`}
                          onClick={() => {
                            if (event.status === "Waiting") {
                              setSelectedEvent(event);
                              setWaitingModal(true);
                            }
                          }}
                          className="relative mt-px flex"
                          style={{
                            gridRow: `${startRow} / span ${heightRow}`,
                            gridColumn:
                              event.status === "Accepted" ? "1 / 11" : "",
                          }}
                        >
                          <div
                            className={`${
                              event.is_mine
                                ? "bg-rose-100 border-rose-300"
                                : event.is_my_club
                                ? "bg-indigo-100 border-indigo-300"
                                : "bg-stone-200 border-stone-300"
                            } ${
                              event.status === "Waiting" && "cursor-pointer"
                            } w-full group inset-1 flex flex-col overflow-y-auto rounded-lg p-4 text-xs leading-5 border overflow-x-hidden`}
                          >
                            {event.status === "Accepted" ? (
                              <>
                                <p
                                  className={`${
                                    event.is_mine
                                      ? "text-rose-600"
                                      : "text-stone-700"
                                  } order-1 text-lg font-semibold`}
                                >
                                  {event.club_name}
                                </p>
                                <p
                                  className={`${
                                    event.is_mine
                                      ? "text-rose-600"
                                      : "text-stone-700"
                                  } text-base order-1`}
                                >
                                  {event.name}
                                </p>
                                <p
                                  className={`${
                                    event.is_mine
                                      ? "text-rose-600"
                                      : "text-stone-700"
                                  } text-sm order-1`}
                                >
                                  {event.phone}
                                </p>
                                <p
                                  className={`${
                                    event.is_mine
                                      ? "text-rose-600"
                                      : "text-stone-700"
                                  } text-base`}
                                >
                                  <time dateTime={timeStart}>
                                    {timeStart.format("HH:mm")} ~{" "}
                                    {timeEnd.add(1, "second").format("HH:mm") === '00:00' ? '24:00' : timeEnd.add(1, "second").format("HH:mm")}
                                  </time>
                                </p>
                              </>
                            ) : (
                              <div className="flex justify-center items-center h-full">
                                <InformationCircleIcon
                                  className={`${
                                    event.is_mine
                                      ? "text-rose-600"
                                      : "text-stone-700"
                                  } h-5 w-5`}
                                />
                                <div className="hidden ml-1 text-base text-gray-600 lg:block">
                                  <div>예약</div>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      );
                    })}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={rentModal}
        setOpen={setRentModal}
        currentDate={currentDate}
        roomId={roomId}
        getDays={getDays}
        isAvailable={isAvailable}
      />
      <WaitingModal open={waitingModal} setOpen={setWaitingModal}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            예약 정보
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="font-medium text-gray-500">동아리</dt>
              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedEvent.club_name}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="font-medium text-gray-500">이름</dt>
              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedEvent.name}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="font-medium text-gray-500">연락처</dt>
              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedEvent.phone}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="font-medium text-gray-500">대관장소</dt>
              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
                {rooms.find((room) => room.id === roomId)?.room}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="font-medium text-gray-500">대관시간</dt>
              <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">
                {`${moment(selectedEvent.time_start).format(
                  "YYYY-MM-DD hh:mm"
                )} ~ ${moment(selectedEvent.time_end)
                  .add(1, "second")
                  .format("YYYY-MM-DD hh:mm")}`}
              </dd>
            </div>
          </dl>
        </div>
      </WaitingModal>
    </div>
  );
}
