import '@toast-ui/editor/dist/toastui-editor-viewer.css';

import React, { useState, useRef, Fragment } from 'react';
import request from 'api/http-request';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { MailIcon, KeyIcon, UserIcon, CheckCircleIcon } from '@heroicons/react/solid';
import { Viewer } from '@toast-ui/react-editor';
import ToS from 'assets/ToS.md';

const Modal = ({ open, setOpen, action }) => {
  const cancelButtonRef = useRef(null);

  const onLoad = async (v) => {
    const result = await fetch(ToS);
    v.setMarkdown(await result.text())
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 mx-3 lg:mx-0 lg:mt-0">
                    <div className="mt-2 text-sm text-gray-500">
                      <Viewer onLoad={onLoad} />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={action}
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 sm:ml-3 sm:w-auto sm:text-sm`}
                  >
                    동의
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    취소
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default function Register() {
  const navigate = useNavigate();

  const [ userEmail, setUserEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setpasswordConfirm ] = useState('');
  const [ name, setName ] = useState('');
  const [ code, setCode ] = useState('');
  const [ isEmailSent, setIsEmailSent ] = useState(false);
  const [ verified, setVerified ] = useState(false);
  const [ terms, setTerms ] = useState(false);
  const [ termsModal, setTermsModal ] = useState(false);

  const sendVerifyAction = async () => {
    if (!userEmail) return toast('아이디를 입력해주세요.', {type: 'error'});

    const result = await request({
      method: 'POST',
      url: `/user/${userEmail}/validation`
    })

    if (result && result.success) {
      setIsEmailSent(true);
      return toast(<div><h3 className="font-bold">인증 메일을 발송했습니다.</h3><p>메일함에서 인증코드를 확인해주세요.</p></div>, {type: 'success'});
    } else {
      return toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  const verifyAction = async () => {
    if (!userEmail) return toast('아이디를 입력해주세요.', {type: 'error'});
    if (!code) return toast('인증코드를 입력해주세요.', {type: 'error'});
    
    const result = await request({
      method: 'GET',
      url: `/cert/${userEmail}/Join/${code}`,
    })

    if (result && result.success) {
      return setVerified(true);
    } else {
      return toast(<div><h3 className="font-bold">인증에 실패했습니다.</h3><p>인증코드를 다시 확인하신 후 입력해주세요.</p></div>, {type: 'error'});
    }
  }
  
  const registerAction = async (event) => {
    event.preventDefault();

    if (!userEmail) return toast('아이디를 입력해주세요.', {type: 'error'});
    if (!verified) return toast('먼저 인증해주세요.', {type: 'error'});
    if (!password) return toast('비밀번호를 입력해주세요.', {type: 'error'});
    if (password !== passwordConfirm) return toast('비밀번호가 일치하지 않습니다.', {type: 'error'});
    if (!name) return toast('이름을 입력해주세요.', {type: 'error'});
    if (!terms) return toast('개인정보수집 및 처리방침에 동의하셔야 합니다.', {type: 'error'});

    const result = await request({
      method: 'POST',
      url: `/user/${userEmail}`,
      data: {
        userEmail,
        code,
        password,
        name,
      },
    });

    if (result && result.success) {
      toast('회원가입이 완료되었습니다.', {type: 'success'});
      navigate('/login');
    } else {
      return toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  return (
    <div className="flex items-center justify-center px-6 lg:px-0" style={{minHeight: 'calc(100vh - 64px)'}}>
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-bold text-gray-900">
            회원가입
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={registerAction}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                아이디
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10 w-0">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MailIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="id"
                    id="id"
                    className={`${isEmailSent && "bg-gray-200"} focus:ring-rose-500 focus:border-rose-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300`}
                    placeholder="아이디"
                    disabled={isEmailSent}
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                  />
                  <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      @korea.ac.kr
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  disabled={isEmailSent}
                  className={`${isEmailSent ? 'bg-gray-200' : 'hover:bg-gray-100'} -ml-px relative inline-flex items-center space-x-2 px-2 lg:px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50`}
                  onClick={sendVerifyAction}
                >
                  <span>인증코드 발송</span>
                </button>
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              인증코드
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <CheckCircleIcon
                    className={[verified ? 'text-emerald-400' : 'text-gray-400', 'h-4', 'w-4'].join(' ')}
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="code"
                  id="code"
                  maxLength={6}
                  className={`${verified && "bg-gray-200"} focus:ring-rose-500 focus:border-rose-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300`}
                  placeholder="인증코드"
                  disabled={verified}
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value.toUpperCase());
                  }}
                />
              </div>
              <button
                type="button"
                className={`${verified ? 'bg-gray-200' : 'hover:bg-gray-100'} -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50`}
                disabled={verified}
                onClick={verifyAction}
              >
                <span>{verified ? '인증완료' : '인증'}</span>
              </button>
            </div>
          </div>

          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                비밀번호
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <KeyIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                    placeholder="비밀번호"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                비밀번호 확인
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <KeyIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                    placeholder="비밀번호 확인"
                    value={passwordConfirm}
                    onChange={(e) => {
                      setpasswordConfirm(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                이름
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <UserIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                    placeholder="이름"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="terms"
                name="terms"
                type="checkbox"
                className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300 rounded"
                checked={terms}
                onChange={() => setTerms(!terms)}
              />
              <label
                className="ml-2 block text-sm text-gray-900"
              >
                <span className="cursor-pointer font-medium" onClick={() => setTermsModal(!termsModal)}>개인정보수집 및 처리방침</span><label htmlFor="terms">에 관한 동의</label>
              </label>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-rose-800 hover:bg-rose-900"
            >
              회원가입
            </button>
          </div>
        </form>
      </div>
      <Modal
        open={termsModal}
        setOpen={setTermsModal}
        action={() => {
          setTerms(true);
          setTermsModal(!termsModal);
        }}
      />
    </div>
  );
};