import { Link } from "react-router-dom";

export default function PageHead({ title, description, to }) {
  return (
    <div className="flex flex-wrap items-end space-y-1 mb-8 lg:space-x-2">
      <div className="flex-none min-w-0">
        <Link to={to}>
          <span className="text-2xl font-bold leading-7 text-gray-900">
            {title}
          </span>
        </Link>
      </div>
      <span className="flex-auto w-full text-sm text-gray-600 lg:w-auto">{description}</span>
    </div>
  );
}