import React, { useState } from 'react';
import request from 'api/http-request';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { KeyIcon } from '@heroicons/react/solid';
import jwtDecode from 'jwt-decode';

export default function ChangePassword({ setToken }) {
  const navigate = useNavigate();
  const [ passwordOld, setPasswordOld ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setpasswordConfirm ] = useState('');
  
  // 비밀번호 변경 요청
  const passwordChangeAction = async (event) => {
    event.preventDefault();
    if (!passwordOld) return toast('기존 비밀번호를 입력해주세요.', {type: 'error'});
    if (!password) return toast('비밀번호를 입력해주세요.', {type: 'error'});
    if (password !== passwordConfirm) return toast('비밀번호가 일치하지 않습니다.', {type: 'error'});

    const userEmail = jwtDecode(localStorage.getItem('token')).email;

    const result = await request({
      method: 'PUT',
      url: `/user/${userEmail}`,
      data: {
        passwordOld,
        passwordNew: password
      },
    });

    if (result && result.success) {
      toast('성공적으로 비밀번호를 변경했습니다. 다시 로그인 해주세요.', {type: 'success'});
      localStorage.removeItem('token');
      setToken('');
      navigate('/login');
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, {type: 'error'});
    }
  }

  return (
    <div
      className="flex items-center justify-center px-6 lg:px-0"
      style={{ minHeight: "calc(100vh - 64px)" }}
    >
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-bold text-gray-900">
            비밀번호 변경
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={passwordChangeAction}>
          <>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  기존 비밀번호
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <KeyIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="password"
                      name="passwordOld"
                      className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                      placeholder="기존 비밀번호"
                      value={passwordOld}
                      onChange={(e) => {
                        setPasswordOld(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  비밀번호
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <KeyIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                      placeholder="비밀번호"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  비밀번호 확인
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <KeyIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="password"
                      name="passwordConfirm"
                      id="passwordConfirm"
                      className="focus:ring-rose-500 focus:border-rose-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                      placeholder="비밀번호 확인"
                      value={passwordConfirm}
                      onChange={(e) => {
                        setpasswordConfirm(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>

          <div className="pt-2">
            <button
              type="submit"
              className={`bg-rose-800 hover:bg-rose-900 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white`}
            >
              비밀번호 변경
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};